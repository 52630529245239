<template>
  <div>
    <ion-header class="px-4 pt-2">
      <ion-toolbar class="pb-2">
        <ion-buttons slot="start">
          <ion-button @click="closeModal" class="w-8 h-8">
            <SvgIcon name="chevron-back-outline" />
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <div class="gradient"></div>
    <ion-content class="ion-padding">
      <main class="px-4 py-4 pt-0 h-full">
        <div class="pb-4 flex flex-col justify-center h-full">
          <fieldset>
            <div
              class="border-gray-200 divide-y divide-gray-200 flex justify-center"
            >
              <QRCanvas
                class="h-50 w-50 sm:h-80 sm:w-80"
                :options="{ data: order.id, cellSize: 8, correctLevel: 'H' }"
              ></QRCanvas>
            </div>
          </fieldset>
        </div>
      </main>
    </ion-content>
  </div>
</template>

<script>
import { Prop, Component, Vue } from "vue-property-decorator";
import { QRCanvas } from "qrcanvas-vue";
import { IonModalController } from "@ionic/vue";

@Component({
  components: {
    QRCanvas
  }
})
export default class QRModal extends Vue {
  @Prop({ required: true, default: "QR" }) title;
  @Prop({ required: true, default: {} }) order;

  data() {
    return {
      isLoading: false
    };
  }

  // Close modal function
  closeModal() {
    this.$ionic.modalController.dismiss();
  }

  mounted() {
    // Listen for the back gesture using popstate
    window.addEventListener("popstate", this.handleBackButton);
  }

  beforeDestroy() {
    // Clean up the event listener when the modal is destroyed
    window.removeEventListener("popstate", this.handleBackButton);
  }

  // Handle the back button action
  handleBackButton(event) {
    // Check if modal is open
    if (this.$ionic.modalController.getTop()) {
      this.closeModal();
      event.preventDefault(); // Prevent default back navigation behavior
    }
  }
}
</script>

<style lang="scss" scoped>
.gradient {
  height: 20px;
  position: relative;
  width: 100%;
  background: radial-gradient(
    ellipse farthest-side at top center,
    rgba(0, 0, 0, 0.06),
    transparent
  );

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    height: 3px;
    background: linear-gradient(
      to right,
      transparent,
      rgba(0, 0, 0, 0.02),
      rgba(0, 0, 0, 0.02),
      transparent
    );
  }
}
</style>
