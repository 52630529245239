
import { Component, Mixins } from "vue-property-decorator";
import "dayjs/locale/zh-cn";
import empty from "is-empty";

import { isBoost, isNestle, isInIframe } from "@/constant/platform";
import { authRestrictedMerchants } from "@/constant/merchant";
import { Loading, SideMenu, Drawer } from "@/components";
import { Header } from "@/components/Navigation";
import Review from "@/components/Custom/Review.vue";
import Modal from "@/components/Feedback/Modal.vue";
import { FooterLogo } from "@/components/General/DynamicImages.vue";
import BottomNav from "@/components/Navigation/BottomNav.vue";
import AppMixin from "@/mixins/App";
import { userStore } from "@/store/modules/user";
import { merchantStore } from "@/store/modules/merchant";
import { cartStore } from "@/store/modules/cart";
import { tableReservationStore } from "@/store/modules/tableReservation";
import { uiStore } from "@/store/modules/ui";
import { loyaltyStore } from "@/store/modules/loyalty";
import { menuStore } from "@/store/modules/menu";
import { trxStore } from "@/store/modules/transaction";
import { isAlaCartePage, isSandbox } from "@/utils/page";
import request from "@/utils/request";
import cookies from "@/utils/cookies";
import rmPlugin from "@/utils/plugin";

@Component({
  name: "App",
  components: {
    BottomNav,
    Header,
    Loading,
    SideMenu,
    Drawer,
    FooterLogo,
    Modal,
    Review
  },
  metaInfo() {
    let facebook_domain_verification = [] as any;
    const subdomain = window.location.host.split(".")[0];
    if (
      subdomain.indexOf("localhost") > -1 ||
      subdomain.indexOf("dev-alacarte") > -1 ||
      subdomain.indexOf("test-demo") > -1
    ) {
      facebook_domain_verification = [
        {
          vmid: "facebook-domain-verification",
          name: "facebook-domain-verification",
          content: "testing"
        }
      ];
    } else if (subdomain.indexOf("selections") > -1) {
      facebook_domain_verification = [
        {
          vmid: "facebook-domain-verification",
          name: "facebook-domain-verification",
          content: "o8olf6zcc5pwj2ey3qb87lgf4lepkh"
        }
      ];
    }
    return {
      title: "à la carte",
      meta: [...facebook_domain_verification]
    };
  }
})
export default class App extends Mixins(AppMixin) {
  beforeMount() {
    merchantStore.UPDATE_HISTORY_LEN(
      JSON.parse(JSON.stringify(window.history.length))
    );
    const urlParams = new URLSearchParams(window.location.search);
    const boostClientId = urlParams.get("clientId");
    const ac = urlParams.get("ac");

    if (ac?.match(/^[0-9a-zA-Z]+$/)) {
      this.affiliateCode = ac;
    } else if (ac) {
      setTimeout(() => {
        this.$ionic.toastController
          .create({
            header: "invalid agent code",
            position: "top",
            color: "primary",
            duration: 2000,
            buttons: [
              {
                icon: "close",
                role: "cancel"
              }
            ]
          })
          .then((x) => x.present());
      }, 1000);
    }

    if (window.location.href.indexOf("/search?id=") > -1) {
      cookies.setSessionStorage("isSearchBarReset", "YES");
    }

    this.isBoost = (boostClientId || "").includes("boost") || isBoost();
    const platform = urlParams.get("platform");
    this.isAlipay = platform == "ALIPAY";
    this.isTng = platform == "TNG";

    // store referred voucher code in local storage
    const externalUserId = urlParams.get("externalUserId");
    const code = urlParams.get("code");

    if (code && externalUserId) {
      cookies.setSessionStorage("refrred-promo-code", code);
      cookies.setSessionStorage("externalUserId", externalUserId);
      const { id } = this.$route.params;
      if (id) {
        cookies.setSessionStorage(`promo-${id}`, externalUserId);
      }
    }
  }

  async created() {
    // Block nestle website, only allow in iframe
    if (isNestle() && !isInIframe()) {
      window.location.href = isSandbox()
        ? "https://sb.alacarte.my"
        : "https://alacarte.my";
      return;
    }

    // Auth user
    if (!this.isAuthenticated) {
      try {
        await this.setPlatform();

        if (empty(this.$route.query?.token)) {
          await userStore.authenticate(this.affiliateCode).finally(async () => {
            this.isLoaded = true;
            uiStore.setLoader(false);
          });
        } else {
          //coming from AliPay miniP
          const token = String(this.$route.query.token);

          await userStore.setAuth(token).finally(() => {
            this.isLoaded = true;
            uiStore.setLoader(false);
          });
        }

        const { id } = this.$route.params;
        if (
          authRestrictedMerchants.includes(merchantStore.merchantID) ||
          authRestrictedMerchants.includes(this.merchantSubdomain)
        ) {
          await merchantStore
            .fetchMerchant(this.merchantSubdomain)
            .then(async () => {
              if(id){
                await merchantStore
                  .fetchStoreSettings({ storeID: id })
                  .then(async () => {
                    if (!menuStore.menus?.length) {
                      await menuStore.fetchMenus(id);
                    }
                  });
              }
            });
        } else{
          if(id){
                await merchantStore
                  .fetchStoreSettings({ storeID: id })
                  .then(async () => {
                    if (!menuStore.menus?.length) {
                      await menuStore.fetchMenus(id);
                    }
                  });
              }
        }

        Promise.all([userStore.fetchProfile()]).finally(async () => {
          if (empty(this.$route.query?.token)) {
            if (
              authRestrictedMerchants.includes(merchantStore.merchantID) ||
              authRestrictedMerchants.includes(this.merchantSubdomain)
            ) {
              if (userStore.user?.profile?.loyaltyID) {
                const payload = {
                  loyaltyID: userStore.user?.profile?.loyaltyID,
                  merchantID: merchantStore.merchantID
                };
                await loyaltyStore.fetchLoyaltyMember(payload);
              }
            }
            switch (this.platform) {
              case "SENHENG":
                if (!userStore.user.senhengUserName) {
                  const urlParams = new URLSearchParams(window.location.search);
                  const accessToken = urlParams.get("access_token");
                  await userStore.oauth({
                    senhengAccessToken: accessToken
                  });
                }
                break;
            }
          }
        });
      } catch (e) {
        try {
          request.post(`v3/log/trace`, {
            data: {
              line: "src/App.vue:827",
              e,
              statusCode: 463
            }
          });
        } catch (e) {
          console.error(e);
        }
        console.error(e);
      }
    }
    this.$router.beforeEach((to, from, next) => {
      if (isNestle()) {
        rmPlugin.init("merchant.", (signedRequest) => {
          this.processSignedRequest(signedRequest);
          authRouteGuard(to, from, next);
        });
      } else {
        authRouteGuard(to, from, next);
      }
    });

    this.$router.afterEach(async (to, from) => {
      if (isNestle()) {
        rmPlugin.init("merchant.", (signedRequest) => {
          this.processSignedRequest(signedRequest);
          postNavigation();
        });
      } else {
        postNavigation();
      }
    });

    const authRouteGuard = async (to, from, next) => {
      const storeId = await cookies.get("currentStore");
      if (
        !userStore.isLoggedIn &&
        to.matched.some((record) => record.meta.requiresAuth) &&
        empty(to.query)
      ) {
        next({ name: "Login" });
      } else if (
        storeId &&
        to.name &&
        !to.params.id &&
        to.matched.some((record) => !record.meta.ignoreStoreId)
      ) {
        next({ name: to.name, params: { id: storeId } });
      }

      // Auth user
      let transitionName = to.meta?.transitionName || from.meta?.transitionName;

      if (transitionName === "slide") {
        const toDepth = to.meta?.depth || 0;
        const fromDepth = from.meta?.depth || 0;
        transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      }

      this.transitionName = transitionName || "fade";

      next();
    };

    const postNavigation = async () => {
      // deferred statements
      setTimeout(async () => {
        let order;
        const { reviewOrderID } = this.$route.query;

        if (reviewOrderID && !userStore.user?.isBinded) {
          userStore.setIsPendingLogin(true);
          this.$router.push({
            name: "Login",
            query: { redirect: "GO_BACK" }
          });
        } else if (userStore.user?.isBinded) {
          userStore.setIsPendingLogin(false);
        }

        order = await trxStore.getReviewOrder(reviewOrderID); //backend will cross check orderId with customerId

        if (order != null) {
          trxStore.setIsReviewOrder(true);
        }
      }, 1200); // add some delay to let the user see the food images before review modal

      const { tableId } = this.$route.query;
      const { id } = this.$route.params;

      if (
        tableId &&
        !(await cookies.getSessionStorage("isGuidedToWalkInOnce"))
      ) {
        cookies.set("selectedDineType", "DINEIN");
        cookies.set("isCutleryRequired", false);
        cookies.setSessionStorage("isGuidedToWalkInOnce", "true");
      }
      if (id) {
        if (tableId) {
          cookies.setSessionStorage(`table-${id}`, String(tableId));
          userStore.setFromQR(true);
        }
        if (
          id &&
          !(merchantStore.currentStore && id === merchantStore.currentStore.id)
        ) {
          if (isAlaCartePage()) {
            merchantStore.clearMerchant();
          }
          merchantStore.clearStore();
          menuStore.clearMenu();
          cookies.set("currentStore", id);

          await merchantStore
            .fetchStoreSettings({ storeID: id })
            .then(() => {});
          if (
            merchantStore.merchant &&
            (authRestrictedMerchants.includes(
              merchantStore.merchant?.merchantId
            ) ||
              authRestrictedMerchants.includes(this.merchantSubdomain)) &&
            !userStore.user.profile?.email
          ) {
            userStore.setIsPendingLogin(true);
            this.$router.push({
              name: "Login",
              query: { redirect: "GO_BACK" }
            });
          }
          const cachedCart = JSON.parse(
            (await cookies.get(`cart-${id}`)) || "[]"
          );
          if (cachedCart) {
            cartStore.loadCart(cachedCart);
          }
          if (!menuStore.menus?.length) {
            await menuStore.fetchMenus(id);
          }
        }

        this.isLoaded = true;
        uiStore.setLoader(false);
      }
    };

    const startApp = async () => {
      trxStore.SET_IS_MEAL_PLAN(
        JSON.parse((await cookies.get("isTransactionListMealPlan")) || "false")
      );
      userStore.SET_IS_BINDED(
        JSON.parse((await cookies.get("isBinded")) || "false")
      );
      userStore.SET_CUSTOMER_ID((await cookies.get("customerID")) || "");
      userStore.SET_BOOST_CUSTOMER_ID(
        (await cookies.get("boostCustomerId")) || ""
      );

      this.bannerVisible = (await cookies.get("hidePrivacyUpdate")) !== "true";

      const paths = window.location.pathname.split("/");
      const storeId = paths.find((each) => Number.isInteger(parseInt(each)));

      if (storeId) {
        const cachedCart = JSON.parse(
          (await cookies.get(`cart-${storeId}`)) || "null"
        );
        if (cachedCart) {
          cartStore.loadCart(cachedCart);
        }

        const cachedReservation = JSON.parse(
          (await cookies.get(`reservation-${storeId}`)) || "null"
        );
        if (cachedReservation) {
          tableReservationStore.setTableReservation(cachedReservation);
        }
      }
    };

    if (isNestle()) {
      rmPlugin.init("merchant.", (signedRequest) => {
        this.processSignedRequest(signedRequest);
        startApp();
      });
    } else {
      startApp();
    }
  }
}
