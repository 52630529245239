
import { Component, Vue, Watch } from "vue-property-decorator";
import { isAlaCartePage } from "@/utils/page";

import { trxStore } from "@/store/modules/transaction";
import { merchantStore } from "@/store/modules/merchant";

import { Card } from "@/components/Order";
import { Tabs, Tab } from "@/components/Tab";
import { uiStore } from "@/store/modules/ui";
import { loyaltyStore } from "@/store/modules/loyalty";
import { ILoyaltyOrder } from "@/store/models/loyalty";
import IOrder from "@/store/models/order";
import { userStore } from "@/store/modules/user";
import { loyaltyRestrictedMerchants } from "@/constant/merchants";
import PaymentOptionsBottomSheet from "@/components/Custom/PaymentOptionsBottomSheet.vue";
import QRModal from "@/components/Custom/QRModal.vue";

@Component({
  components: {
    Card,
    Tabs,
    Tab,
    PaymentOptionsBottomSheet
  }
})
export default class OrderHistory extends Vue {
  private isLoading?: boolean = true;
  selectedTab = "All";
  private refreshInterval;
  private bottomOfWindow = false;

  get merchantID() {
    return merchantStore.merchantID;
  }

  get windowTitle() {
    return (
      merchantStore.currentStore?.name +
      " - " +
      merchantStore.merchant?.company.name
    );
  }
  get allOrders() {
    return trxStore.allOrders;
  }
  get activeOrders() {
    return trxStore.activeOrders;
  }
  get unpaidOrders() {
    return trxStore.unpaidOrders;
  }

  get currentStore() {
    return merchantStore.currentStore;
  }

  get isStoreSelected() {
    return merchantStore?.currentStore?.id;
  }

  get isMealPlan() {
    return trxStore.isMealPlan;
  }

  isPaymentOptsSheetOpened = false;
  selectedPaymentMethod = "";
  selectedOrder: IOrder | undefined = undefined;

  togglePaymentOptsBottomSheet() {
    const bottomSheet: any = this.$refs.paymentOptsBottomSheet;
    const refBottomSheet: any = bottomSheet.$refs.paymentOptsBottomSheet;

    if (!this.isPaymentOptsSheetOpened) {
      refBottomSheet?.open();
    } else {
      refBottomSheet?.close();
    }
  }

  showPaymentOptsSheetStatus(value) {
    this.isPaymentOptsSheetOpened = value;
  }

  handlePayNow(order: IOrder) {
    this.selectedOrder = order;
    if (
      this.selectedOrder &&
      loyaltyRestrictedMerchants.includes(this.selectedOrder?.merchantID)
    ) {
      this.togglePaymentOptsBottomSheet();
    } else {
      if (this.selectedOrder) {
        this.onCreateTransaction("PAYORDER", "", this.selectedOrder?.storeID, [
          this.selectedOrder?.id
        ]);
      }
    }
  }

  handelPaymentOptsSheetConfirmClick() {
    this.togglePaymentOptsBottomSheet();

    if (this.selectedOrder && this.selectedPaymentMethod == "ONLINE_PAYMENT") {
      this.onCreateTransaction("PAYORDER", "", this.selectedOrder?.storeID, [
        this.selectedOrder?.id
      ]);
    } else if (
      this.selectedOrder &&
      this.selectedPaymentMethod == "LOYALTY_CREDIT"
    ) {
      this.onCreateLoyaltyTransaction(
        this.selectedOrder.storeID,
        userStore.profile?.countryCode || "",
        userStore.profile?.phoneNumber || "",
        this.selectedOrder.id,
        this.selectedOrder.storeName,
        this.selectedOrder.tableName,
        this.selectedOrder.customerRemark,
        this.selectedOrder.totalAmount.amount
      );
    }
  }

  setSelectedPaymentMethod(value) {
    this.selectedPaymentMethod = value;
  }

  async onCreateLoyaltyTransaction(
    storeId: string,
    countryCode: string,
    phoneNumber: string,
    orderId: string,
    title: string,
    detail: string,
    additonalData: string,
    amount: number,
    paymentType = "PAYORDER",
    paymentMethod = "",
    isAlipay = false
  ) {
    const order: ILoyaltyOrder = {
      id: orderId,
      title: title,
      detail: detail,
      additionalData: additonalData,
      amount: Math.round(amount)
    };

    const storeID = storeId;
    const orderIDs = [orderId];
     await trxStore.createTransaction({
      paymentType,
      paymentMethod,
      storeID,
      orderIDs,
      isAlipay
    });

    const merchantID = this.merchantID;
    const transaction = await loyaltyStore.spendBalance({
      merchantID,
      storeId,
      countryCode,
      phoneNumber,
      order
    });
    
    if (transaction != null) {
      uiStore.setLoader(false);
      trxStore.clearOrderHistory();
      this.$router.replace({
        name: "OrderSummary",
        query: { orderID: orderId }
      });
    }
  }

  async onCreateTransaction(
    paymentType = "PAYORDER",
    paymentMethod = "",
    storeID = "",
    orderIDs: string[]
  ) {
    const isAlipay = merchantStore.platform == "ALIPAY";
    const isTNG = merchantStore.platform == "TNG";

    const transaction = await trxStore.createTransaction({
      paymentType,
      paymentMethod,
      storeID,
      orderIDs,
      isAlipay
    });
    const { paymentRedirectUrl, transactionData } = transaction as {
      paymentRedirectUrl: string;
      transactionData: string;
    };
    trxStore.clearOrderHistory();
    if (transactionData != "") {
      uiStore.setLoader(false);
      if (isAlipay || isTNG) {
        const result = await this.$miniProgram.tradePay(
          merchantStore.platform,
          transactionData
        );
        this.$router.replace({
          name: "OrderSummary",
          query: { orderID: orderIDs[0] }
        });
      }
    } else if (paymentRedirectUrl) {
      this.$router.replace({
        name: "OrderSummary",
        query: { orderID: orderIDs[0] },
        params: { paymentRedirectUrl }
      });
    }
  }

  onRefreshOrder() {
    trxStore.clearOrderHistory();
    this.fetchOrder();
  }

  openQRModal(order) {
    this.$ionic.modalController
      .create({
        component: QRModal as any,
        componentProps: {
          data: {},
          propsData: {
            title: order ? `ID: ${order.hashId}` : "QR",
            $router: this.$router,
            $t: this.$t,
            self: this,
            order
          }
        }
      })
      .then((m) => {
        m.present();
        history.pushState(null, "", window.location.href);
      });
  }

  async fetchOrder() {
    const orderDineType = this.isMealPlan
      ? ["MEAL_PLAN"]
      : ["DRIVE_THRU", "DELIVERY", "DINEIN", "PICKUP"];
    const merchantID = isAlaCartePage()
      ? ""
      : merchantStore.merchant?.merchantId ||
        this.currentStore?.merchantId ||
        "";
    switch (this.selectedTab) {
      case "Preparing":
        if (
          this.activeOrders.items.length === 0 ||
          (this.bottomOfWindow && this.activeOrders.cursor)
        ) {
          this.isLoading = true;
          await trxStore.getActiveOrders({
            merchantID,
            orderDineType
          });
          this.isLoading = false;
        }
        break;
      case "Unpaid":
        if (
          this.unpaidOrders.items.length === 0 ||
          (this.bottomOfWindow && this.unpaidOrders.cursor)
        ) {
          this.isLoading = true;
          await trxStore.getUnpaidOrders({
            merchantID,
            orderDineType
          });
          this.isLoading = false;
        }
        break;
      default:
        if (
          this.allOrders.items.length === 0 ||
          (this.bottomOfWindow && this.allOrders.cursor)
        ) {
          this.isLoading = true;
          await trxStore.getALLOrders({
            merchantID,
            orderDineType
          });
          this.isLoading = false;
        }
    }
  }

  async onScroll() {
    const { offsetHeight } = this.$el as HTMLElement;
    this.bottomOfWindow =
      document.documentElement.scrollTop + window.innerHeight >= offsetHeight;
    if (this.bottomOfWindow && !this.isLoading) {
      this.fetchOrder();
    }
  }

  @Watch("selectedTab")
  onSelectedTabChanged() {
    this.fetchOrder();
  }
  @Watch("currentStore")
  onChangeCurrentStore(val) {
    if (val) {
      this.fetchOrder();
    }
  }
  @Watch("isMealPlan")
  onDineTypeChanged() {
    this.onRefreshOrder();
  }

  activated() {
    this.fetchOrder();
    window.addEventListener("scroll", this.onScroll);
    //note: this is re-rendering the page and loosing current scroll position and curosr
    this.refreshInterval = setInterval(
      this.onRefreshOrder,
      60000 // 60 seconds to refresh list
    );
  }
  deactivated() {
    window.removeEventListener("scroll", this.onScroll);
    clearInterval(this.refreshInterval);
  }
}
