import { merchantStore } from "@/store/modules/merchant";
import { userStore } from "@/store/modules/user";
import { trxStore } from "./transaction";
import { uiStore } from "./ui";
import { cartStore } from "./cart";

export default {
  cart: cartStore,
  merchant: merchantStore,
  trx: trxStore,
  ui: uiStore,
  user: userStore
};
