
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import { Picture } from "@/components";
import { Component, Vue, Prop } from "vue-property-decorator";
import IStore from "@/store/models/store";
// import Cookies from "js-cookie";
import cookies from "@/utils/cookies";
import { cartStore } from "@/store/modules/cart";
import { merchantStore } from "@/store/modules/merchant";
import { menuStore } from "@/store/modules/menu";
import StoreFood from "@/components/DataDisplay/StoreFood.vue";
import StoreInfo from "@/components/DataDisplay/StoreInfo.vue";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import { isBoost } from "@/constant/platform";

@Component({
  components: {
    Picture,
    StoreFood,
    StoreInfo,
    VueBottomSheet
  }
})
export default class MerchantInfo extends Vue {
  isLoading = false;
  isPromptStoreList = false;

  @Prop() readonly store!: IStore;
  @Prop() readonly isSearch!: boolean;

  created() {
    dayjs.extend(customParseFormat);
    dayjs.extend(isBetween);
    dayjs.extend(utc);
  }

  deactivated() {
    const bottomSheet: any = this.$refs.storeListBottomSheet;
    bottomSheet?.close();
  }

  get platform() {
    return merchantStore.platform?.toLowerCase();
  }
  isFoodStore(store: IStore) {
    // return merchantStore.isFoodStore(store);
    return true;
  }
  get BASE_URL() {
    return process.env.BASE_URL;
  }

  // get foodTags() {
  //   return foodStore.foodTags || {};
  // }

  get filterTags() {
    let filterTags = this.store.filterTagIDs || [];
    this.store.storesUnderSameMerchant?.forEach((store) =>
      filterTags.concat(
        (store.filterTagIDs || []).filter(
          (item) => filterTags.indexOf(item) < 0
        )
      )
    );
    return filterTags;
  }

  get isDefault() {
    return !isBoost();
  }

  get isBoost() {
    return isBoost();
  }

  get now() {
    return dayjs();
  }
  get yesterday() {
    return dayjs().subtract(1, "day");
  }
  get weekDayIndex() {
    return dayjs().day();
  }

  get isStoreClosed() {
    if (!this.store) return false;
    return dayjs(this.store.closedUntil)
      .utc()
      .isAfter(dayjs().utc(), "second");
  }

  get isTodayOpen() {
    //not used in AliPay
    //not necessairly today endAt is there, maybe we're coming just after 12 and there's time from yesterday but later today is closed
    if (this.store) {
      return (
        this.store.currentOpeningHour != null &&
        this.store.currentClosingHour != null
      );
    }

    return false;
  }

  get currentOpeningHour() {
    return this.isTodayOpen
      ? dayjs(this.store.currentOpeningHour).format("HH:mm")
      : "";
  }

  isHaveHoursFromYesterday() {
    //isn't anytime during the day also after midnight ?
    return this.store?.previousClosingHour != ""; // backend already calculated, if it's not empty, means yesterday hours overflowing until current moment
  }

  get isNowWithinBusinessHour() {
    return this.store?.isOpen;
  }

  get isPreOrderAvailable() {
    //not necessairly today endAt is there, maybe we're coming just after 12 and there's time from yesterday but later today is closed
    if (this.store) {
      return (
        this.store.orderAheadDays > 0 && this.store.isDeliveryTimeAvailable
      );
    } else {
      return false;
    }
  }

  get hasStoresUnderMerchant() {
    return (
      !!this.store.storesUnderSameMerchant &&
      this.store.storesUnderSameMerchant.length > 1
    );
  }

  get showStoreRating() {
    return this.store?.rating?.totalRatings >= 10;
  }

  get storeRating() {
    const totalStars = this.store?.rating?.totalRatings * 5;
    const earnedStars = this.store?.rating?.stars;
    return ((earnedStars / totalStars) * 5).toFixed(2);
  }

  get totalRatingCount() {
    return this.store?.rating?.totalRatings;
  }

  isFoodActive(food) {
    return food.isAvailable;
  }

  getItemPrice(amount: number, rate: number) {
    return menuStore.toFormattedTaxInclusive(amount, rate);
  }

  formatDistance(distance) {
    if (+distance > 1000) {
      return `${(+distance / 1000).toFixed(1)} km`;
    } else {
      return `${+distance} m`;
    }
  }

  unavailableText(food) {
    if (!this.isFoodActive(food) && food.nextAvailableSlot != "") {
      // Calculate the next available schedule, nearest to current hour
      const timeFormat = this.$i18n.locale == "cn" ? "A h:mm" : "h:mm A";
      const dayFormat =
        this.$i18n.locale == "cn" ? "M月D日, A h:mm" : "MMM D, h:mm A";
      const now = dayjs();
      const nextSlot = dayjs(food.nextAvailableSlot);

      if (nextSlot.isAfter(now)) {
        const format = now.isSame(nextSlot, "day") ? timeFormat : dayFormat;
        return this.$t("FoodItem.available-time", {
          time: nextSlot.format(format)
        });
      }

      return this.$t("FoodItem.unavailable");
    }
    return "";
  }

  protected async clickStore() {
    if (this.hasStoresUnderMerchant) {
      if (this.isDesktop) {
        this.isPromptStoreList = true;
      } else {
        const bottomSheet: any = this.$refs.storeListBottomSheet;
        bottomSheet.open();
      }
      return;
    }

    cookies.set("currentStore", this.store.id);
    merchantStore.selectStore(null);
    menuStore.loadMenu([]);
    cartStore.clearCart();
    const cachedCart = JSON.parse(
      (await cookies.get(`cart-${this.store.id}`)) || "null"
    );
    if (cachedCart) {
      cartStore.loadCart(cachedCart);
    }
    //removed query since already been handled on app.vue
    this.$router.push({
      name: "StoreView",
      params: { id: this.store.id }
    });
  }

  protected hideStoreList() {
    if (this.isDesktop) {
      this.isPromptStoreList = false;
    } else {
      const bottomSheet: any = this.$refs.storeListBottomSheet;
      bottomSheet.close();
    }
  }

  async onFood(storeID, foodID) {
    cookies.set("currentStore", this.store.id);
    merchantStore.selectStore(null);
    menuStore.loadMenu([]);
    cartStore.clearCart();
    const cachedCart = JSON.parse(
      (await cookies.get(`cart-${this.store.id}`)) || "null"
    );
    if (cachedCart) {
      cartStore.loadCart(cachedCart);
    }
    this.$router.push(`/${storeID}/item/${foodID}?from=merchantInfo`);
  }

  get isDesktop() {
    return window.outerWidth >= 1024;
  }
}
