
import { Component, Watch, Vue } from "vue-property-decorator";
import empty from "is-empty";
import { trxStore } from "@/store/modules/transaction";
import { merchantStore } from "@/store/modules/merchant";
import { userStore } from "@/store/modules/user";
import IOrder from "@/store/models/order";
import { DeliveryStatus } from "@/components/Order";
import { Button, Card, Modal } from "@/components";
import { QRCanvas } from "qrcanvas-vue";
import dayjs, { Dayjs } from "dayjs";
import { IPrice, CampaignDisc, SidesEntity } from "../store/models/food";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "dayjs/locale/zh-cn";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import { getLogo } from "@/components/General/DynamicImages.vue";
import { uiStore } from "@/store/modules/ui";
import { isBoost, isNestle } from "@/constant/platform";
import { cartStore } from "@/store/modules/cart";
import { menuStore } from "@/store/modules/menu";
import cookies from "@/utils/cookies";
import DatePick from "vue-date-pick";
import { cashOnlyMerchants } from "@/constant/cashOnlyMerchants";
import PaymentOptionsBottomSheet from "@/components/Custom/PaymentOptionsBottomSheet.vue";
import { loyaltyRestrictedMerchants } from "@/constant/merchants";
import { loyaltyStore } from "@/store/modules/loyalty";
import { ILoyaltyOrder } from "@/store/models/loyalty";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  components: {
    QRCanvas,
    DeliveryStatus,
    Button,
    Card,
    Modal,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    DatePick,
    PaymentOptionsBottomSheet
  }
})
export default class OrderSummary extends Vue {
  private orderID: string = "";
  private isRedirectFromCheckout: boolean = false;
  public order: IOrder | null = null;
  private progressStepState: { total: number; completed: number } = {
    total: 5,
    completed: 0
  };
  public isModalOpen = false;
  private isPromptSpinAndWin = false;
  private isDismissSpinAndWin = false;
  private refreshInterval;
  private zoom = 16;
  private url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  private attribution =
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
  private mapOptions = { zoomSnap: 0.5, zoomControl: false };
  private icon = getLogo().logo;
  private iconSize = [40, 40];
  private riderLocation = latLng(0, 0);
  private storeLocation = latLng(0, 0);
  private customerLocation = latLng(0, 0);
  mealPlanDate: (string | undefined)[] | undefined = [];
  private dateChanged: boolean = false;
  private addressChanged: boolean = false;
  public hideCta = false;
  noDisabledDays = 0;
  isPaymentOptsSheetOpened = false;
  selectedPaymentMethod = "";

  togglePaymentOptsBottomSheet() {
    const bottomSheet: any = this.$refs.paymentOptsBottomSheet;
    const refBottomSheet: any = bottomSheet.$refs.paymentOptsBottomSheet;

    if (!this.isPaymentOptsSheetOpened) {
      refBottomSheet?.open();
    } else {
      refBottomSheet?.close();
    }
  }

  showPaymentOptsSheetStatus(value) {
    this.isPaymentOptsSheetOpened = value;
  }

  handelPaymentOptsSheetConfirmClick() {
    this.togglePaymentOptsBottomSheet();

    if (this.order && this.selectedPaymentMethod == "ONLINE_PAYMENT") {
      this.onCreateTransaction("PAYORDER", "", this.order?.storeID, [
        this.order?.id
      ]);
    } else if (this.order && this.selectedPaymentMethod == "LOYALTY_CREDIT") {
      this.onCreateLoyaltyTransaction(
        this.order.storeID,
        userStore.profile?.countryCode || "",
        userStore.profile?.phoneNumber || "",
        this.order.id,
        this.order.storeName,
        this.order.tableName,
        this.order.customerRemark,
        this.totalAfterRounding
      );
    }
  }

  setSelectedPaymentMethod(value) {
    this.selectedPaymentMethod = value;
  }

  async onCreateLoyaltyTransaction(
    storeId: string,
    countryCode: string,
    phoneNumber: string,
    orderId: string,
    title: string,
    detail: string,
    additonalData: string,
    amount: number,
    paymentType: string = "PAYORDER",
    paymentMethod: string = "",
    isAlipay: boolean = false
  ) {
    const order: ILoyaltyOrder = {
      id: orderId,
      title: title,
      detail: detail,
      additionalData: additonalData,
      amount: Math.round(amount)
    };
    uiStore.setLoader(true);

    const storeID = storeId;
    const orderIDs = [orderId];
     await trxStore.createTransaction({
      paymentType,
      paymentMethod,
      storeID,
      orderIDs,
      isAlipay
    });

    const merchantID = this.merchantID;
    const transaction = await loyaltyStore.spendBalance({
      merchantID,
      storeId,
      countryCode,
      phoneNumber,
      order
    });

    if (transaction != null) {
      uiStore.setLoader(false);
      trxStore.clearOrderHistory();
      this.$router.go(0);
    }else{
      uiStore.setLoader(false);
      setTimeout(() => {
        this.$ionic.toastController
          .create({
            header: "Payment Failed",
            position: "top",
            color: "danger",
            duration: 2000,
            buttons: [
              {
                icon: "close",
                role: "cancel"
              }
            ]
          })
          .then((x) => x.present());
      }, 1000);

    }
  }

  public countItemsQty(its) {
    let items = JSON.parse(JSON.stringify(its));
    return items.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        current.count = 1;
        return acc.concat([{ ...current, count: 1 }]);
      } else {
        x.count++;
        return acc;
      }
    }, []);
  }

  noBizHour(date) {
    return trxStore.filteredDeliveryTime.some((time) => time.isSame(date));
  }

  isDateDisabled(date: Date) {
    const djsDate = dayjs(date);
    return (
      date.getDay() == 6 ||
      date.getDay() == 0 ||
      dayjs(date).isBefore(this.firstDayAt) ||
      dayjs(date).isAfter(this.maxDate) ||
      this.noBizHour(djsDate)
    );
  }

  public countLabelsQty(labels) {
    return labels.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([{ ...current, count: 1 }]);
      } else {
        x.count++;
        return acc;
      }
    }, []);
  }

  get merchantID() {
    return merchantStore.merchantID;
  }

  get isCashOnlyMerchant() {
    return cashOnlyMerchants.includes(this.order?.merchantID || "");
  }

  get isDesktop() {
    return window.outerWidth >= 1024;
  }

  get isTablet() {
    return window.outerWidth >= 768 && window.outerWidth < 1024;
  }

  get isMobile() {
    return !this.isTablet && !this.isDesktop;
  }

  get isOngoingBoostPayment() {
    return trxStore.isOngoingBoostPayment;
  }
  get isWeb() {
    return merchantStore.isWeb;
  }

  get isBoost() {
    return isBoost();
  }
  get isNestle() {
    return isNestle();
  }

  get isSenHeng() {
    return merchantStore.platform == "SENHENG";
  }

  get orderItems() {
    if (this.order) {
      let { items } = this.order;
      items = items.map((item) => {
        item.name = item.language?.[this.$i18n.locale]?.name || item.name;
        item.description =
          item.language?.[this.$i18n.locale]?.description || item.description;
        return item;
      });
      return items;
    }

    return [];
  }

  get dayjs() {
    return dayjs;
  }

  get platform() {
    return merchantStore.platform;
  }

  get storeName() {
    return this.order?.agent?.storeName || this.order?.storeName;
  }
  get totalAmount() {
    const { totalAmount } = this.order as { totalAmount: IPrice };
    return totalAmount;
  }
  get serviceFeePercentage() {
    return (this.serviceFee / (this.totalAmount.amount / 100)) * 100;
  }

  get serviceFee() {
    const { serviceFeeAmount } = this.order as { serviceFeeAmount: IPrice };
    return serviceFeeAmount.amount;
  }
  get serviceTax() {
    const { serviceTaxAmount } = this.order as { serviceTaxAmount: IPrice };
    return serviceTaxAmount.amount;
  }
  get deliveryFee() {
    const { deliveryFee } = this.order as { deliveryFee: IPrice };
    return deliveryFee.amount;
  }
  get deliveryWaiverFee() {
    const { deliveryWaiverFee } = this.order as { deliveryWaiverFee: IPrice };
    return deliveryWaiverFee.amount;
  }
  get miscellaneousFees() {
    const detail = this.order?.detail;
    let miscellaneousFees = this.order?.miscellaneousFees;
    if (miscellaneousFees && detail) {
      miscellaneousFees = miscellaneousFees.map((fee, i) => {
        fee.description =
          detail?.[this.$i18n.locale]?.miscellaneousFees?.[i] ||
          fee.description;
        return fee;
      });
    }
    return miscellaneousFees ? miscellaneousFees : [];
  }
  get campaignDiscountAmount() {
    const { discounts } = this.order as { discounts: CampaignDisc[] };
    let totalDiscount = 0;
    if (discounts) {
      totalDiscount = discounts.reduce((total, item) => {
        return total + item.quantity * item.discountPerUnit.amount;
      }, 0);
    }
    return totalDiscount;
  }
  get promoDiscountAmount() {
    const { promoCode, promoDiscount } = this.order as {
      promoCode: string;
      promoDiscount: IPrice;
    };

    if (promoCode) {
      return promoDiscount.amount;
    }
    return 0;
  }
  get subtotal() {
    return this.totalAmount.amount;
  }
  get totalBeforeRoundingWithoutPromo() {
    return (
      ((this.subtotal +
        this.serviceFee +
        this.serviceTax +
        this.deliveryFee -
        this.deliveryWaiverFee) /
        100) *
      100
    );
  }
  get totalBeforeRounding() {
    return (
      this.totalBeforeRoundingWithoutPromo -
      this.promoDiscountAmount -
      this.campaignDiscountAmount
    );
  }
  get totalAfterRounding() {
    // return this.round(this.totalBeforeRounding, 0.05);
    return this.totalBeforeRounding;
  }
  get rounding() {
    return this.totalAfterRounding - this.totalBeforeRounding;
  }
  get preparationBufferTime(): number {
    if (this.order?.preparationBufferTime === 0) return 90;
    else return this.order?.preparationBufferTime || 90;
  }

  get hasTableRemark() {
    return !empty(this.order?.tableReservation.remark);
  }

  get firstDayAt() {
    if (dayjs(this.order?.mealPlanFirstDayAt).isAfter(dayjs())) {
      return dayjs(this.order?.mealPlanFirstDayAt).startOf("day");
    }
    return dayjs().startOf("day");
  }

  get maxDate() {
    return this.firstDayAt
      .add(this.order?.mealPlan.validity || 10080, "minute")
      .subtract(1, "day") // first day is included in the validity
      .add(this.noDisabledDays, "day");
  }

  dineTimeDisplay(side: SidesEntity) {
    if (this.isMealPlan) {
      return " - " + this.capitalizeFirstLetter(side.delivery?.dineTime);
    } else {
      return "";
    }
  }

  isMealPlanEditDisabled(side: SidesEntity) {
    return (
      this.order?.paymentStatus != "DONE" || side?.delivery?.status != "EMPTY"
    );
  }

  isDateEditDsiabled(current: string, side: SidesEntity) {
    return current === side?.delivery?.deliverAt;
  }

  isAddressEditDsiabled(current: string, side: SidesEntity) {
    return current === side?.delivery?.address?.addressLine1;
  }

  formatDate(date: string, format: string, add = 0) {
    return dayjs(date)
      .add(add, "m")
      .format(format);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }

  async selectStore() {
    const storeID = this.order?.storeID;
    if (storeID) {
      merchantStore.selectStore(null);
      menuStore.loadMenu([]);
      cartStore.clearCart();
      cookies.set("currentStore", storeID);
      const cachedCart = JSON.parse(
        (await cookies.get(`cart-${storeID}`)) || "null"
      );
      if (cachedCart) {
        cartStore.loadCart(cachedCart);
      }
      this.$router.push({ name: "StoreView", params: { id: storeID } });
    }
  }
  public round(value: number, step: number): number {
    step || (step = 1.0);
    const inv = 1.0 / step;
    return (Math.round((value / 100) * inv) / inv) * 100;
  }

  async onCreateTransaction(
    paymentType = "PAYORDER",
    paymentMethod = "",
    storeID = "",
    orderIDs: string[]
  ) {
    const isAlipay = merchantStore.platform == "ALIPAY";
    uiStore.setLoader(true);
    const transaction = await trxStore.createTransaction({
      paymentType,
      paymentMethod,
      storeID,
      orderIDs,
      isAlipay
    });
    uiStore.setLoader(false);

    const { paymentRedirectUrl, transactionData } = transaction as {
      paymentRedirectUrl: string;
      transactionData: string;
    };

    if (transactionData != "") {
      if (isAlipay) {
        await this.$miniProgram.tradePay(transactionData);
      } else if (this.isBoost || this.isSenHeng) {
        trxStore.SET_BOOST_PAYING_FLAG(this.isBoost);
        window.location.href = transactionData;
      }
    } else if (paymentRedirectUrl) {
      window.location.href = paymentRedirectUrl;
    }
  }

  private formatNumber(number: string): string {
    return parsePhoneNumberFromString(number, "MY")?.number.toString() || "";
  }

  get paymentStatus() {
    const { paymentStatus } = this.order as { paymentStatus: string };
    switch (paymentStatus) {
      case "DONE":
        return {
          key: "DONE",
          icon: "",
          text: this.$t("OrderSummary.success"),
          classColor: "bg-green-200 text-green-700",
          actionText: this.$t("OrderSummary.view")
        };
      case "PENDING":
      case "DEFERRED":
        return {
          key: "PENDING",
          icon: "",
          text:
            paymentStatus == "PENDING"
              ? this.$t("OrderSummary.pending")
              : this.$t("OrderSummary.deferred"),
          classColor:
            paymentStatus == "PENDING"
              ? "bg-yellow-200 text-yellow-700"
              : "bg-purple-200 text-purple-700",
          actionText: this.$t("OrderSummary.pay-now"),
          action: (event, storeID, orderId) => {
            event.stopPropagation();

            if (this.isOngoingBoostPayment) {
              uiStore.setLoader(true);
              setTimeout(() => {
                if (this.order?.paymentStatus != "DONE") {
                  this.onCreateTransaction("PAYORDER", "", storeID, [orderId]);
                }
              }, 8000);
            } else {
              this.onCreateTransaction("PAYORDER", "", storeID, [orderId]);
            }
          }
        };
      case "NOTBILL":
        return {
          key: "NOTBILL",
          icon: "",
          text: this.$t("OrderSummary.cancelled"),
          classColor: "bg-gray-200 text-gray-700",
          actionText: this.$t("OrderSummary.view")
        };
      case "REFUNDED":
        return {
          key: "REFUNDED",
          icon: "",
          text: this.$t("OrderSummary.refunded"),
          classColor: "bg-gray-200 text-gray-700",
          actionText: this.$t("OrderSummary.view")
        };
      default:
        return {
          key: "",
          icon: "",
          text: ""
        };
    }
  }

  get currentDeliveryProgress() {
    const status = { total: 7, completed: 0 };
    const {
      deliveryStatus,
      deliveryMethod,
      orderDineType,
      paymentStatus
    } = this.order as {
      deliveryStatus: string;
      deliveryMethod: string;
      orderDineType: string;
      paymentStatus: string;
    };
    if (orderDineType == "DELIVERY" && deliveryMethod != "OWN") {
      switch (deliveryStatus) {
        case "WAIT_FOR_CONFIRMATION":
          status.completed = 1;
          break;
        case "PROGRESSING":
          status.completed = 2;
          break;
        case "CANCELLED":
          if (paymentStatus == "DONE") {
            status.completed = 3;
          }
          break;
        case "FINDING_COURIER":
          status.completed = 4;
          break;
        case "WAITING_FOR_COURIER":
          status.completed = 5;
          break;
        case "SHIPPING":
          status.completed = 6;
          break;
        case "COMPLETED":
          status.completed = 7;
          break;

        default:
          status.completed = 0;
      }
    } else if (orderDineType == "DELIVERY" && deliveryMethod == "OWN") {
      status.total = 5;
      switch (deliveryStatus) {
        case "WAIT_FOR_CONFIRMATION":
          status.completed = 1;
          break;
        case "PROGRESSING":
          status.completed = 2;
          break;
        case "FINDING_COURIER":
        case "WAITING_FOR_COURIER":
          status.completed = 3;
          break;
        case "SHIPPING":
          status.completed = 4;
          break;
        case "COMPLETED":
          status.completed = 5;
          break;
        default:
          status.completed = 0;
      }
    } else {
      status.total = 3;
      switch (deliveryStatus) {
        case "WAIT_FOR_CONFIRMATION":
          status.completed = 1;
          break;
        case "PROGRESSING":
        case "PENDING_PAYMENT":
          status.completed = 2;
          break;
        case "COMPLETED":
          status.completed = 3;
          break;
        default:
          status.completed = 0;
      }
    }

    return status;
  }

  get deliveryMethod() {
    let method = {};
    const { deliveryMethod } = this.order as { deliveryMethod: string };
    switch (deliveryMethod) {
      case "MRSPEEDY":
        method = { key: "MRSPEEDY", text: "Borzo" };
        break;
      default:
        method = { key: "OWN", text: "Store's own delivery" };
    }
    return method;
  }

  // get paymentMethod() {
  //   let method = {};
  //   const { paymentMethod } = this.transaction as { paymentMethod: string };
  //   switch (paymentMethod) {
  //     case "TNG":
  //       method = { key: "TNG" };
  //       break;
  //     default:
  //       method = { key: "" };
  //   }
  //   return method;
  // }

  get orderDineType() {
    let dineType = {};
    const { orderDineType } = this.order as { orderDineType: string };
    switch (orderDineType) {
      case "PICKUP":
        dineType = {
          key: "PICKUP",
          icon: "pick_up",
          text: this.$t("OrderSummary.pickup")
        };
        break;
      case "DELIVERY":
        dineType = {
          key: "DELIVERY",
          icon: "delivery",
          text: this.$t("OrderSummary.delivery")
        };
        break;
      case "DRIVE_THRU":
        dineType = {
          key: "DRIVE_THRU",
          icon: "drive_thru",
          text: this.$t("OrderSummary.drive-thru")
        };
        break;
      case "DINEIN":
        dineType = {
          key: "DINEIN",
          icon: "walk_in",
          text: this.$t("OrderSummary.walkin")
        };
        break;
      case "MEAL_PLAN":
        dineType = { key: "MEAL_PLAN", icon: "meal_plan", text: "Meal Plan" };
        break;
      default:
        dineType = { key: "", icon: "", text: "" };
    }
    return dineType as { key: string; icon: string; text: string };
  }

  get orderTime() {
    const { createdAt } = this.order as { createdAt: Date };
    let format = "ddd, D/M/YYYY HH:mm";
    if (this.$i18n.locale == "cn") {
      format = "dddd, YYYY年M月D日, HH:mm";
    }
    return dayjs(createdAt).format(format);
  }

  get deliveryTime() {
    const deliveryTime = this.order?.deliveryTime;
    let format = "ddd, D/M/YYYY HH:mm";
    if (this.$i18n.locale == "cn") {
      format = "dddd, YYYY年M月D日, HH:mm";
    }

    let dt = dayjs(deliveryTime).format(format);

    if (
      this.order?.orderDineType == "DELIVERY" ||
      ((this.order?.orderDineType == "PICKUP" ||
        this.order?.orderDineType == "DRIVE_THRU") &&
        this.preparationBufferTime > 90)
    ) {
      dt += ` - ${dayjs(deliveryTime)
        .add(this.preparationBufferTime, "m")
        .format("HH:mm")}`;
    }

    return dt;
  }

  private getActualItemPrice(item) {
    let totalSidesPrice = 0;
    item.sides.forEach((i) => {
      i.items.forEach((side) => {
        totalSidesPrice += side.price.amount;
      });
    });
    return (
      item.price.amount / 100 + (this.isMealPlan ? 0 : totalSidesPrice / 100)
    );
  }

  get currentStore() {
    return merchantStore.currentStore;
  }

  get isMealPlan() {
    return trxStore.isMealPlan;
  }

  async onCancelOrder(event) {
    if (this.order) {
      event.stopPropagation();
      const order: any = await trxStore.cancelOrderById(this.order?.id);
      if (order) {
        this.order = order;
      }
      this.isModalOpen = false;
    }
  }

  onPayNow(event) {
    if (
      this.order &&
      loyaltyRestrictedMerchants.includes(this.order?.merchantID)
    ) {
      this.togglePaymentOptsBottomSheet();
    } else {
      if (this.paymentStatus.action) {
        this.paymentStatus.action(event, this.order?.storeID, this.order?.id);
      }
    }
  }

  async handleSave(s) {
    // TODO: call backend to edit address
    if (this.order?.items[0].sides && this.mealPlanDate) {
      let orderID = this.order?.id;
      let sideID = this.order?.items[0].sides[s]?.id;
      let date: Dayjs = dayjs(this.mealPlanDate[s]);

      const item = await trxStore.updateMealPlan({
        orderID,
        sideID,
        date,
        address:
          this.mealPlanAddress?.[s] ??
          this.order?.items[0].sides[s]?.delivery?.address
      });

      if (item) {
        this.order = item;
        this.$ionic.toastController
          .create({
            header: "Item updated",
            position: "top",
            color: "success",
            duration: 3000
          })
          .then((x) => x.present());
      }
    }
    this.dateChanged = false;
    this.addressChanged = false;
  }

  handleMealPlanDateChange() {
    this.dateChanged = true;
  }
  handleMealPlanAddressChange() {
    this.addressChanged = true;
  }

  get mealPlanAddress() {
    return trxStore.mealPlanAddress;
  }

  handleEditAddress(s) {
    this.$router.push({
      name: "AddressSelect",
      params: { source: "MEAL_PLAN", dateIndex: s }
    });
  }

  private navigate(): void {
    window.open(
      `https://maps.google.com/?q=${this.order?.storeAddressLine1}`,
      "_blank"
    );
  }

  checkIsRefreshOrder() {
    // Refresh the order again
    // 1. if the status shows pending at an interval of 3 seconds for alipay; OR
    // 2. incomplete deliveries as long as it's paid
    // for unpaid order with custoomer in summary page past 15 mins with phone locked, we have to use the visibilitychange listener
    //to update the order once even if platform is web and paymentStatus != "DONE" to update the status to cancelled, else user will still see the
    //pay now button.
    const isRefresh =
      !this.order ||
      (this.order?.orderDineType != "MEAL_PLAN" &&
        (merchantStore.platform == "ALIPAY" || this.isBoost) &&
        this.order?.paymentStatus == "PENDING") ||
      (["DONE", "DEFERRED"].includes(this.order?.paymentStatus) &&
        this.order?.deliveryStatus != "COMPLETED");
    console.log("IS REFRESH", isRefresh);
    return isRefresh;
  }

  async updateOrder() {
    let newOrder = await trxStore.getOrderById(this.orderID);

    if (newOrder == null) {
      this.$router.replace({
        name: "Transaction"
      });
    }
    if (!this.order || JSON.stringify(this.order) != JSON.stringify(newOrder)) {
      this.order = newOrder;
    }
    if (this.order) {
      trxStore.SET_IS_MEAL_PLAN(this.order.orderDineType == "MEAL_PLAN");

      if (this.isOngoingBoostPayment && this.order.paymentStatus == "DONE") {
        uiStore.setLoader(false);
        trxStore.SET_BOOST_PAYING_FLAG(false);
      }

      if (this.order.paidAt && this.order.isEntitledForSpinAndWin) {
        const paidAt = dayjs(this.order.paidAt);
        const now = dayjs();
        // If now is still within 5 mins time frame from the paid At, then promp
        if (now.isBefore(paidAt.add(5, "hour"))) {
          this.isPromptSpinAndWin = true;
        }
      }
      this.riderLocation = latLng(
        this.order.deliveryCourier.latitude,
        this.order.deliveryCourier.longitude
      );
      this.storeLocation = latLng(
        this.order.storeLocation.lat,
        this.order.storeLocation.lng
      );
      this.customerLocation = latLng(
        this.order.address.lat,
        this.order.address.lng
      );
    }
    if (!this.checkIsRefreshOrder()) {
      clearInterval(this.refreshInterval);
    }
  }
  @Watch("order")
  updateDeliverAt() {
    this.findMealPlanDates();
    if (this.isMealPlan && !this.dateChanged) {
      this.mealPlanDate = this.order?.items[0].sides?.map((side) => {
        return side?.delivery?.deliverAt;
      });
    }
    if (this.isMealPlan && !this.addressChanged) {
      const mealPlanAddress = this.order?.items[0].sides?.map((side) => {
        return side?.delivery?.address;
      });
      trxStore.SET_MEAL_PLAN_ADDRESSES(mealPlanAddress);
    }
  }

  findMealPlanDates() {
    if (this.noDisabledDays == 0) {
      let date = this.firstDayAt.toDate();
      let enabledDays = 0;
      this.noDisabledDays = 0;
      for (let j = 0; j <= 365; j++) {
        if (this.isDateDisabled(date)) {
          this.noDisabledDays++;
        } else {
          enabledDays++;
          if (enabledDays == (this.order?.mealPlan?.validity || 10080) / 1440) {
            return;
          }
        }
        date = dayjs(date)
          .add(1, "day")
          .toDate();
      }
      alert("no available delivery time");
      this.$router.go(-1);
    }
  }

  onVsibilityChange() {
    //this will not trigger on first render
    //only after tab is swithced back to, or phone is unlocked
    if (document.visibilityState == "visible") {
      //resume
      this.updateOrder();
    }
  }

  async activated() {
    if (this.$i18n.locale == "cn") {
      dayjs.locale("zh-cn");
    }
    const { orderID } = this.$route.query;
    this.orderID = orderID as string;

    const { paymentRedirectUrl = "" } = this.$route.params;
    if (paymentRedirectUrl !== "") {
      this.isRedirectFromCheckout = true;
      setTimeout(() => {
        this.isRedirectFromCheckout = false;
        uiStore.setLoader(false);
      }, 5000);
      window.location.href = this.$route.params.paymentRedirectUrl;
    }

    this.updateOrder();

    if (this.checkIsRefreshOrder()) {
      this.refreshInterval = setInterval(
        this.updateOrder,
        this.isBoost ? 1000 : 3000
      );
    }
    document.addEventListener("visibilitychange", this.onVsibilityChange); //this doesn't work on focus loss, only on tab change or screen unlock

    let order;
    order = await trxStore.getReviewOrder(this.orderID); //backend will cross check orderId with customerId
    if (order != null) {
      if (!userStore.user?.isBinded) {
        userStore.setIsPendingLogin(true);
        this.$router.push({ name: "Login", query: { redirect: "GO_BACK" } }); //support when we enable dine in without user login
      } else if (userStore.user?.isBinded) {
        userStore.setIsPendingLogin(false);
        trxStore.setIsReviewOrder(true);
      }
    }
  }

  deactivated() {
    document.removeEventListener("visibilitychange", this.onVsibilityChange);
    clearInterval(this.refreshInterval);
  }


  async downloadReceipt() {
  try {
    const response = await trxStore.getOrderReceiptHTML(this.orderID);
  } catch (error) {
    console.error(error);
  }
}







}
