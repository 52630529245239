export const isAlaCartePage = () => {
  return isWithoutSubDomain() || isInStandaloneMode();
};
export const isWithoutSubDomain = () => {
  return (
    window.location.host.startsWith("localhost") ||
    window.location.host.indexOf("192.168") > -1 ||
    window.location.host.startsWith("dev-alacarte") ||
    window.location.host.startsWith("sb.alacarte.my") ||
    window.location.host.startsWith("test-web.alacarte.my") ||
    window.location.host.startsWith("web.alacarte.my") ||
    window.location.host.startsWith("alacarte-web.ap.ngrok.io") ||
    window.location.host.startsWith("alacarte.my")
  );
};
export const isSandbox = () => {
  return (
    window.location.href.indexOf("https://test-") > -1 ||
    window.location.href.indexOf("https://sb.") > -1 ||
    window.location.href.indexOf("localhost") > -1 ||
    window.location.href.indexOf("dev-alacarte") > -1 ||
    window.location.href.indexOf("192.") > -1 ||
    window.location.href.indexOf("ap.ngrok.io") > -1
  );
};
export const isInStandaloneMode = () =>
  (window.matchMedia("(display-mode: standalone)").matches ||
    (window.navigator as any).standalone) &&
  isWithoutSubDomain();
