
import { loyaltyStore } from "@/store/modules/loyalty";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import { Prop, Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    VueBottomSheet
  }
})
export default class PaymentOptionsBottomSheet extends Vue {
  @Prop({ required: false, default: "Payment Method" }) title;
  @Prop({ required: true }) componentRef;
  @Prop({ required: true }) handleClick;

  isOpened: boolean = false;
  selectedPaymentMethod = "";

  data() {
    return {
      isOpened: false as boolean
    };
  }

  get loyaltyCredit() {
    return (loyaltyStore.loyalty.credit / 100).toFixed(2);
  }

  toggleOpenStatus(status) {
    this.isOpened = status;
    this.$emit("isOpened", this.isOpened);
  }

  handleSelectPaymentMethod(selected) {
    this.selectedPaymentMethod = selected;
  }

  handleButtonClick() {
    this.$emit("selectedPaymentMethod", this.selectedPaymentMethod);
    this.handleClick();
  }
}
