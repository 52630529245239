
import { Component, Vue, Watch } from "vue-property-decorator";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { debounce } from "lodash";

import { supportedCountires } from "@/constant/country";
import { userStore } from "@/store/modules/user";
import { merchantStore } from "@/store/modules/merchant";
import { previousPath } from "@/router";
import cookies from "@/utils/cookies";
import { loyaltyStore } from "@/store/modules/loyalty";

@Component
export default class LoginView extends Vue {
  phoneNumber = "";
  countryCode = "60";
  supportedCountires = supportedCountires;
  pending = false;

  otp = "";
  countDown = 0;
  loggingIn = false;
  requestingOTP = false;
  timestamp = 0;
  askCondition = false;
  duration = 0;
  timerCancelToken = "";
  isAttemptedWithInvalidPhone = false;
  get windowTitle() {
    return (
      merchantStore.currentStore?.name +
      " - " +
      merchantStore.merchant?.company.name
    );
  }
  get isValidNumber() {
    return parsePhoneNumberFromString(
      "+" + this.countryCode + this.phoneNumber
    );
  }

  created() {
    if (userStore.profile?.email) {
      this.handleRouteChange();
    } else {
      const unwatch = this.$watch(
        () => userStore.profile,
        (newProfile) => {
          // !this.otp is to avoid duplicate route change if submit login
          if (!this.otp && newProfile?.email) {
            this.handleRouteChange();
            unwatch();
          }
        }
      );
    }
  }

  onSubmit(e) {
    const form = document.querySelector("form");
    if (!form?.reportValidity()) return;
    e.preventDefault();
    this.debouncedSubmit();
  }

  submit() {
    if (this.isRequetOTP) {
      this.onRequestOTP();
    } else {
      this.onLogin();
    }
  }

  public debouncedSubmit = debounce(this.submit, 300);

  get isRequetOTP() {
    return (
      !this.pending ||
      (!this.requestingOTP && this.duration > 119 && !this.loggingIn)
    );
  }
  async onRequestOTP() {
    if (this.isValidNumber) {
      this.isAttemptedWithInvalidPhone = false;
      this.requestingOTP = true;
      await userStore
        .requestOTP({
          countryCode: this.countryCode,
          phoneNumber: this.phoneNumber,
          storeID: merchantStore.currentStore?.id || ""
        })
        .then((req) => {
          if (req) {
            this.pending = true;
            this.timestamp = ~~(Date.now() / 1000);
            if (this.pending) this.setTimer();
            cookies.set("phone", this.phoneNumber);
            cookies.set("otpTimestamp", this.timestamp.toString());
            cookies.set("otpPending", "1");
          }
          setTimeout(() => {
            this.requestingOTP = false;
          }, 1200);
        });
    } else {
      this.isAttemptedWithInvalidPhone = true;
    }
  }

  get merchantID (){
      return merchantStore.merchantID;
    }

  async onLogin() {
    this.loggingIn = true;

    let body = {
      countryCode: this.countryCode,
      phoneNumber: this.phoneNumber,
      otp: this.otp.toString(),
      isNewUserCampaign: userStore.user.isNewUserCampaign
    };
    if (userStore.user.boostCustomerId) {
      Object.assign(body, {
        boostCustomerId: userStore.user.boostCustomerId,
        merchantID: this.merchantID
      });
    }
    Object.assign(body, {
        merchantID: this.merchantID
      });

    const successLogin = await userStore.login(body);


    if (successLogin !== null) {
      if (userStore.user?.profile?.loyaltyID) {
       const payload = {
          merchantID : this.merchantID,
          loyaltyID : userStore.user?.profile?.loyaltyID
        }
        await loyaltyStore.fetchLoyaltyMember(
          payload
        );
      }
      this.handleRouteChange();
      cookies.remove("otpPending");
    } else {
      this.loggingIn = false;
      this.reset();
    }
  }

  handleRouteChange() {
    const { redirect } = this.$route.query as { redirect: string };

    if (redirect) {
      if (!userStore.isProfileCompleted) {
        this.$router.push({ name: "Profile", query: { redirect } });
        return;
      }

      if (redirect === "GO_BACK") {
        previousPath === "/"
          ? this.$router.push({ name: "StoreList" })
          : this.$router.go(-1);
      } else {
        this.$router.replace({ name: redirect });
      }
    } else {
      if (previousPath !== "/login") {
        this.$router.replace({ path: previousPath });
      }
    }
  }

  reset() {
    this.otp = "";
  }

  retry() {
    this.timestamp = 0;
    this.duration = 0;
    this.otp = "";
  }
  setTimer() {
    const timer = setInterval(async () => {
      const now = ~~(Date.now() / 1000);
      this.duration = now - this.timestamp;
      this.askCondition = true;
      if (this.duration > 119 && (await cookies.get("otpPending")) === "1") {
        this.otp = "";
        this.timerCancelToken = "";
        clearInterval(timer);
      }
      if (this.timerCancelToken !== "") {
        this.timerCancelToken = "";
        clearInterval(timer);
      }
    }, 1000);
  }
  @Watch("otp")
  onOptChanged(newOpt) {
    if (newOpt.length === 6) {
      this.onLogin();
    }
  }

  async activated() {
    this.pending = (await cookies.get("otpPending")) === "1";
    this.phoneNumber = (await cookies.get("phone")) || "";
    this.timestamp = ((await cookies.get("otpTimestamp")) || 0) as number;
    if (this.pending) this.setTimer();
  }

  deactivated() {
    this.pending = false;
    this.timerCancelToken = "true";
  }
}
