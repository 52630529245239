import store from "@/store";
import { extend } from "vue/types/umd";
import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule
} from "vuex-module-decorators";
import { ILoyaltyMember, ILoyaltyTransaction, ISpendBalanceRequest, IRefundBalanceRequest } from "../models/loyalty";
import { authRestrictedMerchants } from "@/constant/merchant";
import request from "@/utils/request";

export interface ILoyaltyState {
    loyalty: ILoyaltyMember;
}

@Module({ store, dynamic: true, name: "loyaltyStore" })
class LoyaltyStore extends VuexModule implements ILoyaltyState {
    public loyalty: ILoyaltyMember = {
        id: "",
        name: "",
        email: "",
        nric: "",
        passport: "",
        address: {
            addressLine1: "",
            addressLine2: "",
            postcode: "",
            city: "",
            state: "",
            country: "",
        },
        gender: "",
        state: "",
        referralCode: "",
        birthDate: new Date(),
        loyaltyPoint: 0,
        credit: 0,
        countryCode: "",
        phoneNumber: "",
        profileImageURL: "",
        hasPinCode: false,
        memberTier: {
            label: "",
            minimumPoint: 0,
            description: [],
            discount: {
                type: "",
                amount: 0,
                minimumSpendAmount: 0,
            },
            createdAt: new Date(),
            updatedAt: new Date(),
        },
        status: "",
        createdDateTime: new Date(),
    };


    @Mutation
    async SET_LOYALTY_MEMBER(loyalty: ILoyaltyMember) {
        this.loyalty = loyalty;
    }

    @Action
    public async fetchLoyaltyMember(payload): Promise<ILoyaltyMember | null> {
        if (
            authRestrictedMerchants.includes(payload.merchantID)
        ) {
            try {
                const response: { item: ILoyaltyMember } = await request.get(`v3/loyalty/member/${payload.merchantID}/${payload.loyaltyID}`);
                const { item }: { item: ILoyaltyMember } = response;
                this.SET_LOYALTY_MEMBER(item);
                return item;
            } catch (e) {
                console.log("Error Fetching Loyalty Member", e);
                return null;
            }
        }
        return null;
    }

    @Action
    public async topUpLoyaltyCredit(payload) {
        try {
            const response: { item: { paymentUrl: string } } = await request.post(`v3/loyalty/${payload.id}/topup-online`, {
                topUpAmount: payload.topUpAmount, redirectUrl: payload.redirectUrl, merchantID: payload.merchantID,
            });
            const { item: { paymentUrl } } = response;
            console.log("Payment URL", paymentUrl);
            return paymentUrl;
        } catch (e) {
            console.log("Error Top Up Loyalty Credit", e);
            return "";
        }
    }

    @Action
    public async spendBalance(payload: ISpendBalanceRequest): Promise<ILoyaltyTransaction | null> {
        try {

            const response: { item: ILoyaltyTransaction } = await request.post(`v3/loyalty/balance/spend`, payload);
            return response.item;
        } catch (e) {
            console.log("Error Spending Loyalty Credit", e);
            return null;
        }
    }

    @Action
    public async refundBalance(payload: IRefundBalanceRequest): Promise<ILoyaltyTransaction | null> {
        try {

            const response: { item: ILoyaltyTransaction } = await request.post(`v3/loyalty/balance/refund`, payload);

            return response.item;
        } catch (e) {
            console.log("Error Refund Loyalty Credit", e);
            return null;
        }
    }

}
export const loyaltyStore = getModule(LoyaltyStore);