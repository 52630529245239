import { Component, Vue, Watch } from "vue-property-decorator";
import empty from "is-empty";

import modules from "@/store/modules";
import { isNestle } from "@/constant/platform";
import { isAlaCartePage } from "@/utils/page";
import detectMobile from "@/utils/user-agent";
import cookies from "@/utils/cookies";
import request from "@/utils/request";
import dayjs from "dayjs";
import { locales } from "@/i18n/locale";
import parsePhoneNumberFromString from "libphonenumber-js";

@Component
export default class AppMixin extends Vue {
  isDrawerOpen = false;
  transitionName = "fade";
  isLoaded = false;
  prompt = false;
  isAlipay = false;
  isSenHeng = false;
  isBoost = false;
  isTng = false;
  bannerVisible = false;
  affiliateCode: string | null = null;

  get isAuthenticated() {
    return !empty(modules.user.user.accessToken);
  }
  get isNestleDev() {
    return isNestle() && window.location.host.indexOf("localhost") > -1;
  }

  get isReviewOrder() {
    return modules.trx.isReviewOrder;
  }

  get isOngoingBoostPayment() {
    return modules.trx.isOngoingBoostPayment;
  }
  get isLoading() {
    return modules.ui.isLoading;
  }
  get isFoodRedirect() {
    return this.$route.name == "FoodView";
  }
  get isStoreRedirect() {
    return this.$route.name == "StoreView";
  }
  get isTransactionRedirect() {
    return this.$route.name == "OrderSummary";
  }

  get isAddressRedirect() {
    return this.$route.name == "AddAddress";
  }

  get isVehicleRedirect() {
    return this.$route.name == "VehicleInfo";
  }
  get isWeb() {
    return modules.merchant.isWeb;
  }

  get isMobile() {
    return detectMobile();
  }
  get subtotal() {
    return modules.cart.subtotalAmount;
  }
  get totalItems() {
    return modules.cart.totalItems;
  }
  get currentStore() {
    return modules.merchant.currentStore;
  }
  get isStoreView() {
    const { id = "" } = this.$route.params;
    return id !== "";
  }
  get userStore() {
    return modules.user;
  }
  get merchantSubdomain() {
    const subdomain = window.location.host.split(".")[0];
    if (subdomain.indexOf("192") > -1) {
      return "test-demo";
    } else if (
      subdomain.indexOf("localhost") > -1 ||
      subdomain.indexOf("dev-alacarte") > -1 ||
      subdomain === "sb" ||
      window.location.host.indexOf("ap.ngrok.io") > -1
    ) {
      return "test-web";
    } else if (
      window.location.host.startsWith("alacarte.my") ||
      window.location.host.startsWith("web.alacarte.my")
    ) {
      return "web";
    }

    return subdomain;
  }

  get isAlaCarteRootPage() {
    const isRootPath = window.location.pathname === "/";
    return isAlaCartePage() && isRootPath;
  }

  get shareableLink() {
    return `${window.location.protocol}//${window.location.host}/${this.currentStore?.id}`;
  }

  get platform() {
    return modules.merchant.platform;
  }

  get env() {
    return process.env;
  }

  get isNestle() {
    return isNestle();
  }

  get isUserBinded() {
    return modules.user.user.isBinded;
  }

  @Watch("isUserBinded")
  async onUserChanged(userBinded: boolean, _) {
    try {
      // bind referred voucher to user
      const externalUserId = await cookies.getSessionStorage("externalUserId");
      const code = await cookies.getSessionStorage("refrred-promo-code");

      if (userBinded && code && externalUserId) {
        await request.post(`v3/web/voucher/bind`, { code, externalUserId });

        // remove once successfully bound
        cookies.removeSessionStorage("refrred-promo-code");
        cookies.removeSessionStorage("externalUserId");

        this.$ionic.toastController
          .create({
            header:
              "Voucher issued successfully, please find it on the checkout page.",
            position: "top",
            color: "success",
            duration: 1500,
            buttons: [
              {
                icon: "close",
                role: "cancel"
              }
            ]
          })
          .then((x) => x.present());
      }
    } catch (e) {}
  }

  reload() {
    window.location.reload();
  }

  onFooterIconClicked() {
    window.location.href = "https://alacarte.my";
  }
  onDismissPrivacyBanner() {
    cookies.set("hidePrivacyUpdate", true);
    this.bannerVisible = false;
  }

  formatNumber(number: string): string {
    return parsePhoneNumberFromString(number, "MY")?.number.toString() || "";
  }

  async setPlatform() {
    // Set the language
    if (modules.merchant.platform == "") {
      if (
        this.$route.query?.clientId &&
        this.$route.query?.clientId.includes("boost") &&
        this.$route.query?.customerId
      ) {
        const boostCustomerId =
          typeof this.$route.query.customerId === "string"
            ? this.$route.query.customerId
            : this.$route.query.customerId.join("");
        await modules.user.setBoostCustomerId(boostCustomerId);
      }

      if (this.isAlipay) {
        this.$i18n.locale = locales.CN;
        modules.merchant.updatePlatform({ platform: "ALIPAY", isWeb: false });
        modules.merchant.updateLocale(locales.CN);
        modules.ui.setLoader(false);
        dayjs.locale("zh-cn");
      } else if (this.isTng) {
        this.$i18n.locale = locales.EN;
        modules.merchant.updatePlatform({ platform: "TNG", isWeb: false });
        modules.ui.setLoader(false);
        modules.merchant.updateLocale(locales.EN);
      } else if (this.isBoost) {
        modules.merchant.updatePlatform({ platform: "BOOST", isWeb: true });
        modules.merchant.updateLocale(locales.EN);
      } else if (this.isSenHeng) {
        modules.merchant.updatePlatform({ platform: "SENHENG", isWeb: true });
        modules.merchant.updateLocale(locales.EN);
      } else {
        modules.merchant.updatePlatform({ platform: "WEB", isWeb: true });
        modules.merchant.updateLocale(locales.EN);
      }
      // IMPORTANT: need to add the platform classname in the list below so that during compile and build, it will detect it and compile to css
      // boost, senheng, fa-icon

      if (this.isAlipay || this.isTng) {
        if (this.$route.query?.customerID) {
          const customerID = String(this.$route.query.customerID);
          await modules.user.setCustomerID(customerID);
        }
      }

      // TODO: For TNGD set the platform accordingly
      modules.merchant.updateIsShowApprovedOnly(
        isAlaCartePage() ||
          this.isAlipay ||
          this.isTng ||
          this.isBoost ||
          this.isSenHeng
      );
      if (this.isWeb) {
        document.title = "à la carte";
      }
    }
  }

  processSignedRequest(signedRequest) {
    if (signedRequest) {
      let decodedSR = JSON.parse(atob(signedRequest.split(".")[1]));
      modules.user.setPluginUser({
        merchantID: decodedSR["merchantId"],
        countryCode: decodedSR["userCountryCode"],
        phoneNumber: decodedSR["userPhone"]
      });
    }
  }
}
