
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import { Prop, Component, Vue } from "vue-property-decorator";
import { userStore } from "@/store/modules/user";
import { loyaltyStore } from "@/store/modules/loyalty";
import { merchantStore } from "@/store/modules/merchant";

@Component({
  components: {
    VueBottomSheet
  }
})
export default class TopUpBottomSheet extends Vue {
  @Prop({ required: false, default: "Loyalty Credit • Top Up" }) title;
  @Prop({ required: true }) componentRef;
  @Prop({ required: true }) handleClick;

  isOpened: boolean = false;
  inputError: boolean = false; // Tracks if there's an input error
  inputAmount: number = 0; // Input amount
  errorMessage: string = ""; // Error message for invalid input

  get merchantID() {
    return merchantStore.merchantID;
  }

  data() {
    return {
      isOpened: false as boolean
    };
  }

  toggleOpenStatus(status) {
    this.isOpened = status;
    this.$emit("isOpened", this.isOpened);
  }

  handleButtonClick() {
    this.topUpLoyaltyCredit()  }


  validateInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove any non-numeric characters except decimal point
    value = value.replace(/[^\d.]/g, '');

    // Restrict to two decimal places
    const decimalIndex = value.indexOf('.');
    if (decimalIndex !== -1) {
      const decimals = value.substring(decimalIndex + 1);
      if (decimals.length > 2) {
        this.inputError = true;
        this.errorMessage = "Only 2 digits allowed after the decimal point.";
        return; // Stop further processing
      }
      value = value.substring(0, decimalIndex + 3); // Keep up to 2 digits after decimal
    }

    const numericValue = parseFloat(value);

    // Validation logic
    if (isNaN(numericValue) || numericValue < 1) {
      this.inputError = true;
      this.errorMessage = "Minimum amount is 1";
    } else if (numericValue > 20000) {
      this.inputError = true;
      this.errorMessage = "Amount must not exceed 20,000.";
    } else {
      this.inputError = false; 
      this.errorMessage = ""; 
      this.inputAmount = Math.round(numericValue * 100); 
    }

  }


  async topUpLoyaltyCredit() {
    try {
      const payload = {
        merchantID: this.merchantID,
        id: userStore.user.profile?.loyaltyID,
        topUpAmount: this.inputAmount,
        redirectUrl: `${window.location.origin}${this.$router.currentRoute.fullPath}`
      }
      const paymentLink = await loyaltyStore.topUpLoyaltyCredit(payload);
      window.location.href = paymentLink;
    } catch (error) {
      console.log(error);
    }
  }
}
