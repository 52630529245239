import store from "@/store";
import IMerchant from "@/store/models/merchant";
import IStore, { IStoreUnderSameMerchant } from "@/store/models/store";
import ITable from "@/store/models/table";
import { cartStore } from "@/store/modules/cart";
import { menuStore } from "@/store/modules/menu";
// import Cookies from "js-cookie";
import cookies from "@/utils/cookies";
import request from "@/utils/request";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import qs from "qs";
import { isAlaCartePage } from "@/utils/page";

import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import { IAddress } from "../models/user";
dayjs.extend(utc);

function isStoreClosed(store) {
  if (!store) return false;
  return dayjs(store.closedUntil)
    .utc()
    .isAfter(dayjs().utc(), "second");
}

function isPreOrderAvailable(store) {
  //not necessairly today endAt is there, maybe we're coming just after 12 and there's time from yesterday but later today is closed
  if (store) {
    return store.orderAheadDays > 0 && store.isDeliveryTimeAvailable;
  } else {
    return false;
  }
}

function isNowWithinBusinessHour(store) {
  return store?.isOpen;
}

function isTodayOpen(store) {
  //not used in AliPay
  //not necessairly today endAt is there, maybe we're coming just after 12 and there's time from yesterday but later today is closed
  if (store) {
    return store.currentOpeningHour != null && store.currentClosingHour != null;
  }

  return false;
}

export interface IMerchantState {
  stores: IStore[];
  table: ITable;
  merchant: IMerchant | null;
  currentStore: IStore | null;
  fetching: boolean;
  hasCustomerEntry: boolean;
}

@Module({ store, dynamic: true, name: "merchantStore" })
class MerchantStore extends VuexModule implements IMerchantState {
  public stores: IStore[] = [];
  public initHistoryLen: number = 0;
  public table: ITable = {
    id: "",
    isActive: false,
    isDefault: false,
    name: "",
    type: ""
  };
  public merchant: IMerchant | null = null;
  public currentStore: IStore | null = null;
  public fetching = false;
  public hasCustomerEntry = false;
  public isShowApprovedOnly = false;
  public platform = "";
  public locale = "";
  public isWeb = false;
  // migrated from alacarte-vue
  public allStores = [] as IStore[];
  public closedStores = [] as IStore[];
  public preorderStores = [] as IStore[];
  public searchClosedStores = [] as IStore[];
  public searchPreorderStores = [] as IStore[];
  public fetchingStores = false;
  public storesCursor = "" as string | number | null;
  public searchStoresCursor = "" as string | number | null;
  public featuredStores = [] as IStore[];
  public searchStores = [] as IStore[];
  public iteration = 1 as number;
  public foodBusinessCategories = [
    "5499",
    "5811",
    "5812",
    "5813",
    "5814",
    "FOODS AND BEVERAGES"
  ];
  public merchantID = "";

  @Mutation
  private UPDATE_MERCHANT(payload: IMerchant) {
    this.merchant = payload;
  }

  @Mutation
  public async UPDATE_MERCHANT_ID(merchantID: string) {
    this.merchantID = merchantID;
  }

  @Mutation
  public UPDATE_HISTORY_LEN(payload: number) {
    this.initHistoryLen = payload;
  }
  @Mutation
  private UPDATE_STORES(payload: IStore[]) {
    this.stores = payload;
  }
  @Mutation
  private UPDATE_TABLE(payload: ITable) {
    cookies.set("WECAFE-TN", payload.name);
    this.table = payload;
  }
  @Mutation
  public async UPDATE_CURRENT_STORE(payload: IStore | null) {
    if (payload !== null) {
      cookies.set("WECAFE-SN", payload.name);
      const initialMiscFees = await cookies.get("miscFees");
      const miscFees = JSON.stringify(payload.miscellaneousFees);
      cookies.set("miscFees", miscFees);
      // If misc fees has been updated, then need to refresh the cart subtotal
      if (miscFees !== initialMiscFees) {
        cartStore.refreshSubtotal();
      }
    }
    this.currentStore = payload;
  }
  @Mutation
  private UPDATE_CUSTOMER_ENTRY(payload: boolean) {
    this.hasCustomerEntry = payload;
  }
  @Mutation
  private UPDATE_IS_SHOW_APPROVED_ONLY(payload: boolean) {
    this.isShowApprovedOnly = payload;
  }
  @Mutation
  private UPDATE_PLATFORM(payload: string) {
    this.platform = payload;
  }
  @Mutation
  private UPDATE_IS_WEB(payload: boolean) {
    this.isWeb = payload;
  }
  @Mutation
  private UPDATE_LOCALE(locale: string) {
    this.locale = locale;
  }
  @Mutation
  private CLEAR_STORE() {
    this.currentStore = null;
  }

  @Mutation
  private CLEAR_MERCHANT() {
    this.merchant = null;
  }

  @Mutation
  private NEXT_ITERATION() {
    this.iteration += 50;
  }

  @Mutation
  public resetStores() {
    this.storesCursor = "";
    this.allStores = [];
    this.closedStores = [];
    this.preorderStores = [];
  }

  @Mutation
  public async resetSearchStore() {
    this.searchStores = [];
    this.searchClosedStores = [];
    this.searchPreorderStores = [];
    this.searchStoresCursor = "";
  }

  @Mutation
  public COPY_TO_SEARCH_STORE() {
    this.searchStores = [...this.allStores];
    this.searchClosedStores = [...this.closedStores];
    this.searchPreorderStores = [...this.preorderStores];
    this.searchStoresCursor = this.storesCursor;
  }

  @Action
  public updateCustomerEntry(payload: boolean) {
    this.UPDATE_CUSTOMER_ENTRY(payload);
  }
  @Action
  public selectStore(storeId: string | null) {
    const store = this.stores.find((store) => store.id === storeId) || null;
    this.UPDATE_CURRENT_STORE(store);
  }
  @Action
  public updateIsShowApprovedOnly(payload: boolean) {
    this.UPDATE_IS_SHOW_APPROVED_ONLY(payload);
  }
  @Action
  public updatePlatform(payload) {
    this.UPDATE_PLATFORM(payload.platform);
    this.UPDATE_IS_WEB(payload.isWeb);
  }
  @Action
  public updateLocale(locale: string) {
    this.UPDATE_LOCALE(locale);
  }
  @Action
  public async fetchStores(payload) {
    try {
      const { subdomain }: { subdomain: string } = payload;
      const response: any = await request.get(`v3/web/stores/${subdomain}`);
      const {
        items,
        merchant
      }: {
        items: IStore[];
        merchant: IMerchant;
      } = response;
      this.UPDATE_MERCHANT(merchant);
      this.UPDATE_STORES(items);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  public async fetchMerchant(subdomain: string) {
    try {
      const response: { item: IMerchant } = await request.get(
        `v3/merchant/${subdomain}`
      );
      this.UPDATE_MERCHANT_ID(response.item.merchantId);
    } catch (e) {
      console.error(e);
    }
  }
  @Action
  public async fetchStoreSettings(payload) {
    try {
      const { storeID }: { storeID: string } = payload;
      const {
        item: { merchant, store, table, hasCustomerEntry }
      }: {
        item: {
          merchant: IMerchant;
          store: IStore;
          table: ITable;
          hasCustomerEntry: boolean;
        };
      } = await request.get(
        `v3/web/store/${storeID}/store-settings?${qs.stringify(
          {
            isLanding: isAlaCartePage()
          },
          { arrayFormat: "repeat" }
        )}`
      );
      this.UPDATE_MERCHANT(merchant);
      this.UPDATE_CURRENT_STORE(store);
      this.UPDATE_TABLE(table);
      this.UPDATE_CUSTOMER_ENTRY(hasCustomerEntry);
    } catch (e) {
      console.error(e);
    }
  }
  @Action
  public clearStore() {
    this.CLEAR_STORE();
  }
  @Action
  public clearMerchant() {
    this.CLEAR_MERCHANT();
  }

  // migrate from alacarte-vue

  lastStoresHash = "";

  @Mutation
  private SET_FETCHING(state: boolean) {
    this.fetchingStores = state;
  }

  @Mutation
  private PUSH_STORES({
    stores,
    isSearch,
    isAppendSearch
  }: {
    stores: IStore[];
    isSearch: boolean | null;
    isAppendSearch: boolean | null;
  }) {
    if (isSearch) {
      let iterationHasSameMerchant = false;
      if (!isAppendSearch) this.searchStores = [];
      for (var i = 0; i < stores.length; i++) {
        var matchedStoreIndex = this.searchStores.findIndex((el) => {
          return el.merchant.merchantId == stores[i].merchant.merchantId;
        });

        if (matchedStoreIndex >= 0) {
          iterationHasSameMerchant = true;
          var matchedStore = this.searchStores[matchedStoreIndex];
          var storeUnderMerchant = <IStoreUnderSameMerchant>{
            badges: stores[i].badges,
            id: stores[i].id,
            name: stores[i].name,
            addressLine1: stores[i].addressLine1,
            addressLine2: stores[i].addressLine2,
            postCode: stores[i].postCode,
            city: stores[i].city,
            state: stores[i].state,
            isOpen: stores[i].isOpen,
            distance: stores[i].distance,
            closedUntil: stores[i].closedUntil,
            orderAheadDays: stores[i].orderAheadDays,
            isDeliveryTimeAvailable: stores[i].isDeliveryTimeAvailable,
            isVoucherPromo: stores[i].isVoucherPromo,
            isDeliveryPromo: stores[i].isDeliveryPromo,
            supportedDineType: stores[i].supportedDineType,
            currentOpeningHour: stores[i].currentOpeningHour,
            currentClosingHour: stores[i].currentClosingHour,
            nextOpeningHour: stores[i].nextOpeningHour,
            previousClosingHour: stores[i].previousClosingHour,
            filterTagIDs: stores[i]?.filterTagIDs,
            rating: stores[i]?.rating
          };

          if (!!matchedStore.storesUnderSameMerchant) {
            matchedStore.storesUnderSameMerchant.push(storeUnderMerchant);
          } else {
            var currentStore = <IStoreUnderSameMerchant>{
              badges: matchedStore.badges,
              id: matchedStore.id,
              name: matchedStore.name,
              addressLine1: matchedStore.addressLine1,
              addressLine2: matchedStore.addressLine2,
              postCode: matchedStore.postCode,
              city: matchedStore.city,
              state: matchedStore.state,
              distance: matchedStore.distance,
              closedUntil: matchedStore.closedUntil,
              orderAheadDays: matchedStore.orderAheadDays,
              isDeliveryTimeAvailable: matchedStore.isDeliveryTimeAvailable,
              isVoucherPromo: matchedStore.isVoucherPromo,
              isDeliveryPromo: matchedStore.isDeliveryPromo,
              supportedDineType: matchedStore.supportedDineType,
              isOpen: matchedStore.isOpen,
              currentOpeningHour: matchedStore.currentOpeningHour,
              currentClosingHour: matchedStore.currentClosingHour,
              nextOpeningHour: matchedStore.nextOpeningHour,
              previousClosingHour: matchedStore.previousClosingHour,
              filterTagIDs: matchedStore?.filterTagIDs,
              rating: matchedStore.rating
            };
            matchedStore.storesUnderSameMerchant = [
              currentStore,
              storeUnderMerchant
            ];
          }
        } else {
          this.searchStores.push(stores[i]);
        }
      }
      if (iterationHasSameMerchant) {
        this.searchStores[0].dummyId += 1000;
      }
    } else {
      //this.allStores = [...this.allStores, ...stores];
      let iterationHasSameMerchant = false;
      for (var i = 0; i < stores.length; i++) {
        var matchedStoreIndex = this.allStores.findIndex((el) => {
          return el.merchant.merchantId == stores[i].merchant.merchantId;
        });

        if (matchedStoreIndex >= 0) {
          iterationHasSameMerchant = true;
          var matchedStore = this.allStores[matchedStoreIndex];
          var storeUnderMerchant = <IStoreUnderSameMerchant>{
            badges: stores[i].badges,
            id: stores[i].id,
            name: stores[i].name,
            addressLine1: stores[i].addressLine1,
            addressLine2: stores[i].addressLine2,
            postCode: stores[i].postCode,
            city: stores[i].city,
            state: stores[i].state,
            isOpen: stores[i].isOpen,
            distance: stores[i].distance,
            closedUntil: stores[i].closedUntil,
            orderAheadDays: stores[i].orderAheadDays,
            isDeliveryTimeAvailable: stores[i].isDeliveryTimeAvailable,
            isVoucherPromo: stores[i].isVoucherPromo,
            isDeliveryPromo: stores[i].isDeliveryPromo,
            supportedDineType: stores[i].supportedDineType,
            currentOpeningHour: stores[i].currentOpeningHour,
            currentClosingHour: stores[i].currentClosingHour,
            nextOpeningHour: stores[i].nextOpeningHour,
            previousClosingHour: stores[i].previousClosingHour,
            filterTagIDs: stores[i].filterTagIDs,
            rating: stores[i].rating
          };

          if (!!matchedStore.storesUnderSameMerchant) {
            matchedStore.storesUnderSameMerchant.push(storeUnderMerchant);
          } else {
            var currentStore = <IStoreUnderSameMerchant>{
              badges: matchedStore.badges,
              id: matchedStore.id,
              name: matchedStore.name,
              addressLine1: matchedStore.addressLine1,
              addressLine2: matchedStore.addressLine2,
              postCode: matchedStore.postCode,
              city: matchedStore.city,
              state: matchedStore.state,
              distance: matchedStore.distance,
              closedUntil: matchedStore.closedUntil,
              orderAheadDays: matchedStore.orderAheadDays,
              isDeliveryTimeAvailable: matchedStore.isDeliveryTimeAvailable,
              isVoucherPromo: matchedStore.isVoucherPromo,
              isDeliveryPromo: matchedStore.isDeliveryPromo,
              supportedDineType: matchedStore.supportedDineType,
              isOpen: matchedStore.isOpen,
              currentOpeningHour: matchedStore.currentOpeningHour,
              currentClosingHour: matchedStore.currentClosingHour,
              nextOpeningHour: matchedStore.nextOpeningHour,
              previousClosingHour: matchedStore.previousClosingHour,
              filterTagIDs: matchedStore.filterTagIDs,
              rating: matchedStore.rating
            };
            matchedStore.storesUnderSameMerchant = [
              currentStore,
              storeUnderMerchant
            ];
          }
        } else {
          this.allStores.push(stores[i]);
        }
      }
      if (iterationHasSameMerchant) {
        this.allStores[0].dummyId += 1000;
      }
    }
  }

  @Mutation
  private SET_CLOSE_STORES({
    isSearch,
    stores
  }: {
    isSearch: boolean | null;
    stores: IStore[];
  }) {
    if (isSearch) {
      this.searchClosedStores = [...stores];
    } else {
    }
    this.closedStores = stores;
  }

  @Mutation
  private SET_PREORDER_STORES({
    isSearch,
    stores
  }: {
    isSearch: boolean | null;
    stores: IStore[];
  }) {
    if (isSearch) {
      this.searchPreorderStores = [...stores];
    } else {
      this.preorderStores = stores;
    }
  }

  @Action
  public async getStores({
    searchInput,
    address,
    rcursor,
    isSearch,
    isAppendSearch,
    foodTagId
  }: {
    searchInput: string | null;
    address: IAddress;
    rcursor: string | number | null;
    isSearch: boolean | null;
    isAppendSearch: boolean | null;
    foodTagId: any;
  }) {
    try {
      this.SET_FETCHING(true);
      //dont' clear closedStores and preorderStores before they're appened to bottom of resutls after empty cursor
      let isFetchMore = true;
      let noFetched = 0;

      while (isFetchMore) {
        let { items, cursor } = (await request.get(
          `v4/web/find-nearby-stores?${qs.stringify({
            searchInput,
            longitude: address.lng,
            latitude: address.lat,
            cursor: rcursor,
            foodTagID: foodTagId
          })}`
        )) as { items: IStore[]; cursor: string | number | null };

        this.NEXT_ITERATION();
        items = items.map((item, i) => {
          return {
            ...item,
            dummyId: i + this.iteration
          };
        });
        items = items.filter((s) => s.isShowInMainPage);
        const closedStores = [
          ...(isSearch ? this.searchClosedStores : this.closedStores),
          ...items.filter((s) => !s.isOpen && s.orderAheadDays == 0)
        ];
        const preorderStores = [
          ...(isSearch ? this.searchPreorderStores : this.preorderStores),
          ...items.filter((s) => !s.isOpen && s.orderAheadDays > 0)
        ];
        const openStores = items.filter(
          (s) =>
            !closedStores.some((x) => x.id === s.id) &&
            !preorderStores.some((x) => x.id === s.id)
        );
        rcursor = cursor;
        if (cursor || openStores.length > 0) {
          this.SET_CLOSE_STORES({ isSearch, stores: closedStores });
          this.SET_PREORDER_STORES({ isSearch, stores: preorderStores });
          this.PUSH_STORES({ stores: openStores, isSearch, isAppendSearch });
          noFetched += openStores.length;

          if (noFetched > 3) isFetchMore = false;
        }

        if (!cursor) {
          isAppendSearch = true;
          this.PUSH_STORES({
            stores: [...preorderStores, ...closedStores],
            isSearch,
            isAppendSearch
          });

          isFetchMore = false;
        }
      }
      this.UPDATE_CURSOR({ isSearch, curosr: rcursor });
      this.SET_FETCHING(false);
    } catch (e) {
      console.error(e);
    }
  }

  @Mutation
  private UPDATE_CURSOR(payload: {
    isSearch: boolean | null;
    curosr: string | number | null;
  }) {
    if (payload.isSearch) {
      this.searchStoresCursor = payload.curosr;
    } else {
      this.storesCursor = payload.curosr;
    }
  }

  get isFoodStore() {
    return (store: IStore | null) => {
      return this.foodBusinessCategories.includes(
        store?.businessCategory || ""
      );
    };
  }
}
export const merchantStore = getModule(MerchantStore);
