import store from "@/store";
import ICustomer from "@/store/models/customer";
import IUser, { IPluginUser, IProfile } from "@/store/models/user";
import { merchantStore } from "@/store/modules/merchant";
import request from "@/utils/request";
// import Cookies from "js-cookie";
import cookies from "@/utils/cookies";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import * as Sentry from "@sentry/vue";
import { isAlaCartePage } from "@/utils/page";
import empty from "is-empty";
import { loyaltyRestrictedMerchants } from "@/constant/merchants";

export interface IUserState {
  isPendingLogin: boolean;
  user: IUser;
  ipAddr: string;
  location: {
    lat: number;
    lng: number;
  };
}

@Module({ store, dynamic: true, name: "userStore" })
class UserStore extends VuexModule implements IUserState {
  public isPendingLogin = false;

  public user: IUser = {
    accessToken: "",
    isBinded: false,
    merchant: "",
    profile: null,
    customerID: "",
    boostCustomerId: "",
    senhengUserName: "",
    completedOrdersQty: -1,
    isFromQR: false,
    isNewUserCampaign: false,
    affiliateCode: "",
    pluginUser: { merchantID: "", countryCode: "", phoneNumber: "" }
  };

  public ipAddr = "";
  public location = {
    lat: NaN,
    lng: NaN
  };

  get merchantID () {
    return merchantStore.merchantID;
  }

  get customerID() {
    return this.user?.customerID || "";
  }
  get profile() {
    return this.user.profile;
  }
  get isAuthenticated() {
    return !!this.user.accessToken;
  }
  get isLoggedIn() {
    return this.user.isBinded;
  }
  get isNewUser() {
    return this.user.completedOrdersQty == 0;
  }
  get isProfileCompleted() {
    const isEmailValid = /.+@.+\..+/.test(
      String(this.profile?.email).toLowerCase()
    );
    return (
      this.profile &&
      this.profile.name?.trim().length > 0 &&
      this.profile.email?.trim().length > 0 &&
      isEmailValid
    );
  }
  get isFromQR() {
    return this.user.isFromQR;
  }
  get isNewUserCampaign() {
    return this.user.isNewUserCampaign;
  }

  @Mutation
  async setIsPendingLogin(i: boolean) {
    this.isPendingLogin = i;
  }

  @Mutation
  async SET_ACCESS_TOKEN(newToken: string) {
    cookies.set("WECAFE-TOKEN", newToken);
    this.user.accessToken = newToken;
  }
  @Mutation
  async SET_PROFILE(user: IProfile) {
    Sentry.setUser({
      username: user.name,
      id: this.customerID,
      email: user.email
    });
    Sentry.setTag("phone", user.countryCode + user.phoneNumber);
    const deliveryAddress = await cookies.get("delivery:location");
    if (
      deliveryAddress &&
      !user.addresses?.some(
        (address) => JSON.stringify(address) == deliveryAddress
      )
    ) {
      cookies.remove("delivery:location");
    }
    this.user.profile = user;
  }
  @Mutation
  SET_IS_BINDED(status: boolean) {
    cookies.set("isBinded", status.toString());
    this.user.isBinded = status;
  }
  @Mutation
  SET_CUSTOMER_ID(customerID: string) {
    cookies.set("customerID", customerID);
    this.user.customerID = customerID;
  }
  @Mutation
  SET_AFFILIATE_CODE(affiliateCode: string) {
    this.user.affiliateCode = affiliateCode;
  }
  @Mutation
  SET_BOOST_CUSTOMER_ID(customerId: string) {
    Sentry.setTag("boostCustomerId", customerId);
    this.user.boostCustomerId = customerId;
  }
  @Mutation
  SET_PLUGIN_USER(pluginUser: IPluginUser) {
    this.user.pluginUser = pluginUser;
  }
  @Mutation
  private SET_IP(ip: string) {
    this.ipAddr = ip;
  }
  @Mutation
  private SET_LOCATION(lat: number, lng: number) {
    this.location = { lat, lng };
  }
  @Mutation
  private SET_COMPLETED_ORDERS_QTY(count: number) {
    this.user.completedOrdersQty = count;
  }
  @Mutation
  private SET_FROM_QR(bool: boolean) {
    this.user.isFromQR = bool;
  }
  @Mutation
  private SET_NEW_USER_CAMPAIGN(bool: boolean) {
    this.user.isNewUserCampaign = bool;
  }

  @Action
  public async setMetrics(ip: string, lat: number, lng: number) {
    this.SET_IP(ip);
    this.SET_LOCATION(lat, lng);
  }

  @Action
  public async authenticate(affiliateCode: string | null) {
    try {
      let body = {
        customerId: this.customerID,
        platform: merchantStore?.platform
      };
      if (!empty(affiliateCode)) {
        Object.assign(body, {
          affiliateCode
        });
      }

      const response: any = await request.post("v3/auth", body);

      // user module only handle data for user,
      // thus we only grab token property from the response object
      const {
        token,
        customer
      }: { token: string; customer: ICustomer } = response;

      if (token) {
        this.SET_ACCESS_TOKEN(token);
        // return token;
      }
      if (customer) {
        this.SET_CUSTOMER_ID(customer.ID);
        this.SET_AFFILIATE_CODE(customer.AffiliateCode);
      }
      // send payload to merchant store to further process
      // {root: true} !important
      // this.context.dispatch("merchantStore/updateStoreData", response, { root: true });
    } catch (e) {
      try {
        await request.post(`v3/log/trace`, {
          data: {
            line: "src/store/modules/user.ts:199",
            e,
            statusCode: 463
          }
        });
      } catch (e) {
        console.error(e);
      }

      // cookies.remove("WECAFE-TOKEN");
      this.SET_CUSTOMER_ID("");
      console.error(e);
      // return "";
    }
  }
  @Action
  public async setAuth(accessToken: string) {
    this.SET_ACCESS_TOKEN(accessToken);
  }
  @Action
  public async setCustomerID(customerID: string) {
    this.SET_CUSTOMER_ID(customerID);
  }
  @Action
  public async setBoostCustomerId(customerId: string) {
    this.SET_BOOST_CUSTOMER_ID(customerId);
  }
  @Action
  public async setPluginUser(payload: IPluginUser) {
    this.SET_PLUGIN_USER(payload);
  }
  @Action
  public async setFromQR(bool: boolean) {
    this.SET_FROM_QR(bool);
  }
  @Action
  public async setNewUserCampaign(bool: boolean) {
    this.SET_NEW_USER_CAMPAIGN(bool);
  }
  @Action
  public async login(body): Promise<IProfile | null> {
    try {
      const { isBinded = false, item = {}, orders = 0 } = (await request.post(
        "v3/web/login",
        body
      )) as {
        isBinded: boolean;
        item: IProfile;
        orders: number;
      };

      this.SET_PROFILE(item as IProfile);
      this.SET_IS_BINDED(isBinded);
      this.SET_COMPLETED_ORDERS_QTY(orders);

      return item as IProfile;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  @Action
  public async oauth(body): Promise<IProfile | null> {
    try {
      const { isBinded = false, item = {}, orders = 0 } = (await request.post(
        "v3/web/oauth",
        body
      )) as {
        isBinded: boolean;
        item: IProfile;
        orders: number;
      };

      if (item) {
        this.SET_PROFILE(item as IProfile);
        this.SET_IS_BINDED(isBinded);
        this.SET_COMPLETED_ORDERS_QTY(orders);
      }

      return item as IProfile;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  @Action
  async requestOTP({
    countryCode,
    phoneNumber,
    storeID
  }: {
    countryCode: string;
    phoneNumber: string;
    storeID: string;
  }): Promise<any> {
    try {
      const response = await request.post("v3/request-otp", {
        countryCode,
        phoneNumber,
        storeID
      });

      return true;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  @Action
  async fetchProfile() {
    let body = {};
    if (userStore.user.boostCustomerId) {
      Object.assign(body, {
        boostCustomerId: userStore.user.boostCustomerId
      });
    }
    if (userStore.user?.pluginUser?.phoneNumber) {
      Object.assign(body, {
        pluginUser: userStore.user.pluginUser
      });
    }
    try {
      const { isBinded = false, item = {}, orders = 0 } = (await request.post(
        "v3/web/profile",
        body
      )) as {
        isBinded: boolean;
        item: IProfile;
        orders: number;
      };
      this.SET_PROFILE(item as IProfile);
      this.SET_IS_BINDED(isBinded);
      this.SET_COMPLETED_ORDERS_QTY(orders);
      return item;
    } catch (e) {
      console.error(e);
    }
  }
  @Action
  async logout() {
    let body = {};
    if (userStore.user.boostCustomerId) {
      Object.assign(body, {
        boostCustomerId: userStore.user.boostCustomerId
      });
    }
    try {
      const { token = "", customerId = "" } = (await request.post(
        "v3/web/logout",
        body
      )) as {
        token: string;
        customerId: string;
      };
      this.SET_IS_BINDED(false);
      this.SET_CUSTOMER_ID(customerId);
      this.SET_ACCESS_TOKEN(token);
      this.SET_PROFILE({} as IProfile);
    } catch (e) {
      console.error(e);
    }
  }
  @Action
  async updateProfile(user: IProfile) {
    try {
     const payload = {
        ...user,
        merchantId: loyaltyRestrictedMerchants.includes(this.merchantID) ? this.merchantID : null
      };
      console.log("payload", payload);
      const { item = {} } = (await request.patch("v3/web/profile", payload)) as {
        item: IProfile;
      };
      this.SET_PROFILE(item as IProfile);
      return item;
    } catch (e) {
      console.error(e);
    }
  }
  @Action
  async updateWalkInEntry(payload): Promise<boolean> {
    try {
      await request.post(
        `v3/web/store/${merchantStore.currentStore?.id}/customer/entry`,
        payload
      );
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
  @Action
  async fetchStoresDistance({ lat, lng }: { lat: number; lng: number }) {
    if (!lat || !lng) return null;

    try {
      let subdomain = window.location.host.split(".")[0];
      subdomain =
        subdomain.indexOf("192") > -1 ||
        subdomain.indexOf("localhost") > -1 ||
        subdomain.indexOf("dev-alacarte") > -1 ||
        window.location.host.indexOf("ap.ngrok.io") > -1
          ? "test-demo"
          : subdomain;

      const response = await request.post(
        `v3/web/stores/distance/${subdomain}`,
        {
          latitude: lat,
          longitude: lng
        }
      );
      return response;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  @Action
  async enterSpinAndWin() {
    try {
      const { item = "" } = (await request.post("v3/web/game/enter", {
        storeID: merchantStore.currentStore?.id,
        isLanding: isAlaCartePage()
      })) as {
        item: string;
      };

      return item;
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async fetchGameChances() {
    try {
      const { item = 0 } = (await request.get("v3/web/game/chances")) as {
        item: Number;
      };

      return item;
    } catch (e) {
      console.error(e);
    }
  }

  //migrate from alacarte-vue
  get getCurrentPositionPromise(): Promise<any> {
    return new Promise((resolve, reject) =>
      navigator.geolocation.watchPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      })
    );
  }

  @Action
  getCurrentPosition(): Promise<{ latitude: number; longitude: number }> {
    return this.getCurrentPositionPromise
      .then((position) => {
        const { latitude, longitude } = position.coords;
        this.SET_LOCATION(latitude, longitude);
        if (latitude && longitude) {
          return {
            latitude,
            longitude
          };
        } else throw Error("Permission Denied");
      })
      .catch(async () => {
        return fetch("https://geoip.revenuemonster.my/json/")
          .then((resp) => resp.json())
          .then((resp) => {
            const location = {
              latitude: resp.latitude,
              longitude: resp.longitude
            };
            return location;
          })
          .catch(() => {
            return {
              //in case of failure, use Kuala Lumpur's coordiantes
              latitude: 3.139003,
              longitude: 101.686855
            };
          });
      });
  }
}
export const userStore = getModule(UserStore);
