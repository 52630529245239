
// @ is an alias to /src
import { Component, Vue, Watch } from "vue-property-decorator";

import { userStore } from "@/store/modules/user";
import { merchantStore } from "@/store/modules/merchant";
import { cartStore } from "@/store/modules/cart";
import { menuStore } from "@/store/modules/menu";
import { tableReservationStore } from "@/store/modules/tableReservation";
import { loyaltyStore } from "@/store/modules/loyalty";

import IFood from "@/store/models/food";
import IMenu from "@/store/models/menu";
import ITableReservation from "@/store/models/tableReservation";

// import LongListItem from "@/components/LongListItem.vue";
import FoodItem from "@/components/Food/FoodItem.vue";

import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";

import Driver from "driver.js";
import "@/assets/driver.scss";
import utc from "dayjs/plugin/utc";
import { miniProgramPlatforms } from "@/constant/platform";
import empty from "is-empty";

import Fuse from "fuse.js";
import { debounce } from "lodash";

import { RecycleScroller } from "vue-virtual-scroller";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import cookies from "@/utils/cookies";

import { DefaultBanner } from "@/components/General/DynamicImages.vue";
import { loyaltyRestrictedMerchants } from "@/constant/merchants";
import { isBoost } from "@/constant/platform";
import PromoModal from "@/components/Custom/PromoModal.vue";

import { Swiper as SwiperClass, Mousewheel } from "swiper/js/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper.scss";
// import 'swiper/swiper-bundle.min.css'
import { isNestle, isInIframe } from "@/constant/platform";
import rmPlugin from "@/utils/plugin";
import TopUpBottomSheet from "@/components/Custom/TopUpBottomSheet.vue";
import MessageModal from "@/components/Custom/MessageModal.vue";
import { Carousel, Slide } from "vue-carousel";

SwiperClass.use([Mousewheel]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

@Component({
  components: {
    FoodItem,
    RecycleScroller,
    DefaultBanner,
    VueBottomSheet,
    Swiper,
    SwiperSlide,
    TopUpBottomSheet,
    MessageModal,
    Carousel,
    Slide
  },
  filters: {
    lowercase: function(value) {
      value = value.toLowerCase();
      return value.toString();
    }
  }
})
export default class StoreView extends Vue {
  showAddr = false;
  // itemComponent = LongListItem;
  focusingMenu = "";
  yScrolling = false;
  xScrolling = false;
  driver: Driver | null = null;
  query = "";
  tempTop = 0;
  queryList: Array<IFood> = [];
  isSearching?: boolean = false;
  timeFormat = "HH:mm";
  selectedReserveDayIndex = 0;
  unsavedReserveTimeIndex = 0;
  selectedReserveDeliveryTime = "";
  reservePax = 1;
  isReserveLoading = false;
  reserveRemark = "";

  swiperOptions = {
    direction: "vertical",
    slidesPerView: 8,
    spaceBetween: 4,
    centeredSlides: true,
    mousewheel: {
      releaseOnEdges: false
    },
    grabCursor: true,
    touchReleaseOnEdges: true
  };

  isTopUpSheetOpened = false;

  carouselConfig = {
    autoplay: true,
    loop: true,
    perPage: 1,
    speed: 1000,
    paginationPosition: "bottom-overlay",
    paginationActiveColor: "grey"
  };

  get loyaltyPoint() {
    return loyaltyStore.loyalty.loyaltyPoint;
  }

  get loyaltyCredit() {
    return (loyaltyStore.loyalty.credit / 100).toFixed(2);
  }

  get showStoreRating() {
    return (this.currentStore?.rating?.totalRatings || 0) >= 10;
  }

  get storeRating() {
    const totalStars = (this.currentStore?.rating?.totalRatings || 0) * 5;
    const earnedStars = this.currentStore?.rating?.stars || 0;
    return ((earnedStars / totalStars) * 5).toFixed(2);
  }

  get totalRatingCount() {
    return this.currentStore?.rating?.totalRatings;
  }

  get reservePreOrderDays() {
    if (this.currentStore) {
      const { tableReservationTime = [] } = this.currentStore as {
        tableReservationTime: string[][];
      };
      let filteredDeliveryTime = tableReservationTime.map((each, index) => {
        return this.now.add(index, "day");
      });
      return filteredDeliveryTime.filter(
        (time, i) => tableReservationTime?.[i]?.length > 0
      );
    }
    return [];
  }

  get isNestle() {
    return isNestle();
  }

  get reservedTable() {
    return tableReservationStore.tableReservation;
  }

  get isTableReserved() {
    return !empty(tableReservationStore.tableReservation?.startAt);
  }

  get reservedTableText() {
    if (!this.reservedTable) {
      return "";
    }

    let startAt = dayjs(this.reservedTable.startAt).format("D/MM, h:mm A");
    let endAt = dayjs(this.reservedTable.endAt).format("h:mm A");
    return `${this.$t("StoreView.reserved", {
      pax: this.reservedTable.noOfPax,
      datetime: `${startAt} - ${endAt}`
    })}`;
  }

  get isMenuLoading() {
    return menuStore.isLoading;
  }
  get campaignPromo() {
    return menuStore.campaigns;
  }

  get hasCampaign() {
    return !empty(menuStore.campaigns);
  }

  get isOpen(): boolean {
    return merchantStore.currentStore?.isOpen || false;
  }

  get showBusinessHours() {
    return merchantStore.currentStore?.showBusinessHours || false;
  }

  get businessHours() {
    return merchantStore.currentStore?.businessHours;
  }

  get nextOpeningHour(): string {
    return merchantStore.currentStore?.nextOpeningHour
      ? dayjs(merchantStore.currentStore?.nextOpeningHour).format("HH:mm")
      : "";
  }

  get previousClosingHour(): string {
    return merchantStore.currentStore?.previousClosingHour
      ? dayjs(merchantStore.currentStore?.previousClosingHour).format("HH:mm")
      : "";
  }

  get currentOpeningHour(): string {
    return merchantStore.currentStore?.currentOpeningHour
      ? dayjs(merchantStore.currentStore?.currentOpeningHour).format("HH:mm")
      : "";
  }

  get currentClosingHour(): string {
    return merchantStore.currentStore?.currentClosingHour
      ? dayjs(merchantStore.currentStore?.currentClosingHour).format("HH:mm")
      : "";
  }

  get windowTitle() {
    return (
      merchantStore.currentStore?.name +
      " - " +
      merchantStore.merchant?.company.name
    );
  }
  get orderAheadDays(): number {
    return merchantStore.currentStore?.orderAheadDays || 0;
  }
  get deliveryTime() {
    return (
      merchantStore.currentStore?.deliveryTime.filter(
        (time) => time.length > 0
      ) || []
    );
  }
  get reservationTime() {
    return (
      merchantStore.currentStore?.tableReservationTime?.filter(
        (time) => time.length > 0
      ) || []
    );
  }
  get voucherCodes() {
    return (
      merchantStore.currentStore?.voucherCodes?.filter(
        (code) => !code?.isHidden
      ) || []
    );
  }
  get deliveryWaiver() {
    return merchantStore.currentStore?.deliveryWaiver || [];
  }
  get isPreOrderAvailable() {
    return this.orderAheadDays > 0 && this.deliveryTime.length > 0;
  }
  get isEnabledLoyalty() {
    return merchantStore.merchant?.isEnabledLoyalty;
  }

  get isWhatsAppEnabled() {
    return merchantStore.currentStore?.whatsapp.isEnableWhatsAppLink;
  }
  get isTapauMerchant() {
    // if (merchantStore.merchant?.merchantId)
    //   return tapauMerchants.includes(merchantStore.merchant?.merchantId);
    return false;
  }
  get isStoreClosed() {
    return dayjs(merchantStore.currentStore?.closedUntil)
      .utc()
      .isAfter(this.now.utc(), "second");
  }

  get isTodayOpen() {
    return this.currentOpeningHour != "" && this.currentClosingHour != "";
  }

  get isAvailableSlotsToday() {
    return (merchantStore?.currentStore?.deliveryTime[0]?.length as number) > 0;
  }

  get isHaveHoursFromYesterday() {
    return this.previousClosingHour != ""; // backend already calculated, if it's not empty, means yesterday hours overflowing until current moment
  }

  get isNowWithinBusinessHour() {
    return this.isOpen;
  }

  get now() {
    return dayjs();
  }
  get yesterday() {
    return dayjs().subtract(1, "day");
  }
  get merchant() {
    return merchantStore.merchant;
  }
  get merchantStore() {
    return merchantStore;
  }
  get merchantIdentifier() {
    return this.merchant?.uId;
  }
  get currentStore() {
    return merchantStore.currentStore;
  }
  get totalStores() {
    return merchantStore.stores?.length;
  }
  get cart() {
    return cartStore.cart;
  }
  get user() {
    return userStore.user;
  }
  get getFoods() {
    const menus = this.getMenus;
    const foods = [] as any;

    menus.forEach((each) => {
      foods.push(...(each.foods || []));
    });

    return foods;
  }

  get restrictedMerchant() {
    return merchantStore.merchant
      ? loyaltyRestrictedMerchants.includes(merchantStore.merchantID)
      : false;
  }

  generateRandomId = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  get getMenus() {
    const menus = menuStore.menus;
    const isUsePlatformDetail = miniProgramPlatforms.includes(
      merchantStore.platform
    );
    for (let i = 0; i < menus.length; i++) {
      if (parseInt(menus[i].id) == 0) {
        menus[i].id = this.generateRandomId(23);
      }
      menus[i].name = isUsePlatformDetail
        ? !empty(menus[i].platformDetail?.language?.[this.$i18n.locale]?.name)
          ? menus[i].platformDetail?.language?.[this.$i18n.locale]?.name
          : menus[i].name
        : !empty(menus[i].language?.[this.$i18n.locale]?.name)
        ? menus[i].language?.[this.$i18n.locale]?.name
        : menus[i].name;
    }
    return Object.freeze(menus);
  }

  get supportedDineTypes() {
    return this.currentStore?.supportedDineType;
  }

  get isBoost() {
    return isBoost();
  }

  get isMiniProgram() {
    return merchantStore.platform !== "WEB";
  }

  get screenWidth() {
    return window.screen.width;
  }

  get screenHeight() {
    return window.screen.height / 1.5;
  }

  get isDesktop() {
    return this.screenWidth > 767;
  }

  get isTablet() {
    return window.outerWidth >= 768 && window.outerWidth < 1024;
  }

  get isMobile() {
    return !this.isTablet && !this.isDesktop;
  }

  get isLoginAlrdy() {
    return userStore.isLoggedIn;
  }

  formatDate(date: string, format: string, add = 0) {
    return dayjs(date)
      .add(add, "m")
      .format(format);
  }

  guide() {
    this.driver = new Driver({
      doneBtnText: "" + this.$t("StoreView.done"),
      closeBtnText: "" + this.$t("StoreView.close"),
      nextBtnText: "" + this.$t("StoreView.next"),
      prevBtnText: "" + this.$t("StoreView.previous")
    });
    this.driver.defineSteps([
      {
        element: "#d_profile_lg",
        popover: {
          className: "",
          title: "" + this.$t("StoreView.your-profile"),
          description: "" + this.$t("StoreView.your-profile-guide"),
          position: "left"
        }
      },
      // {
      //   element: "#d_storename",
      //   popover: {
      //     className: "",
      //     title: "Store Name",
      //     description: "Press here to watch store details.",
      //     position: "bottom"
      //   }
      // },
      // {
      //   element: "#d_changestore",
      //   popover: {
      //     className: "",
      //     title: "Change Store",
      //     description: "Press here to select different stores by location.",
      //     position: "bottom"
      //   }
      // },
      // {
      //   element: "#d_profile",
      //   popover: {
      //     className: "",
      //     title: "Your profile",
      //     description: "Press here to login or go to your profile page.",
      //     position: "left"
      //   }
      // },
      {
        element: "#d_menulist",
        popover: {
          className: "",
          title: "" + this.$t("StoreView.menus"),
          description: "" + this.$t("StoreView.menus-guide"),
          position: "top"
        }
      }
    ]);
    this.driver.start();
  }

  reserveTableGuide() {
    const driver = new Driver();
    driver.highlight({
      element: "#d_table_reserve",
      popover: {
        title: "Table Reservation",
        description: "You can now reserve a table to dine in!"
      }
    });
  }

  openReserveTableBottomSheet() {
    const bottomSheet: any = this.$refs.reserveTableBottomSheet;
    bottomSheet?.open();
  }

  closeReserveTableBottomSheet() {
    const bottomSheet: any = this.$refs.reserveTableBottomSheet;
    bottomSheet?.close();
  }

  async reserveTable() {
    this.isReserveLoading = true;
    if (!this.selectedReserveDeliveryTime)
      this.selectedReserveDeliveryTime = this.reservationTime[
        this.selectedReserveDayIndex
      ][0];
    tableReservationStore.updateReserveDayIndex(this.selectedReserveDayIndex);
    tableReservationStore.updateReserveDeliveryTime(
      this.selectedReserveDeliveryTime
    );
    let deliveryTime = this.getDeliveryTime(
      this.reservePreOrderDays[this.selectedReserveDayIndex],
      this.selectedReserveDeliveryTime
    ).toISOString();
    const reservation = await tableReservationStore.reserveTable({
      storeID: this.currentStore ? this.currentStore?.id : "",
      startAt: deliveryTime,
      noOfPax: this.reservePax,
      remark: this.reserveRemark
    });

    this.isReserveLoading = false;
    this.closeReserveTableBottomSheet();
  }

  removeTableReservation() {
    if (this.currentStore)
      tableReservationStore.removeTableReservation(this.currentStore?.id);
  }

  onDayChange(e) {
    const { $swiper } = this.$refs.daySwiper as any;
    this.selectedReserveDayIndex = $swiper.realIndex;
    this.selectedReserveDeliveryTime = this.reservationTime[
      $swiper.realIndex
    ][0];
  }

  onTimeChange(e) {
    const { $swiper } = this.$refs.timeSwiper as any;
    this.unsavedReserveTimeIndex = $swiper.realIndex;
    this.selectedReserveDeliveryTime = this.reservationTime[
      this.selectedReserveDayIndex
    ][$swiper.realIndex];
  }

  getDeliveryTime(selectedDay, selectedTime) {
    return selectedDay
      .hour(dayjs(selectedTime).hour())
      .minute(dayjs(selectedTime).minute());
  }

  menuClicked(menuId: string) {
    window.requestAnimationFrame(() => {
      window.scrollTo({
        top:
          (document.getElementById("menu-section-" + menuId)?.offsetTop || 0) -
          this.tempTop,
        left: 0,
        behavior: "smooth"
      });
      this.yScrolling = true;
    });
    this.yScrolling = false;
  }
  detectInBound() {
    (this.getMenus as IMenu[]).forEach((menu) => {
      const el = document.getElementById("menu-section-" + menu.id);
      const top = el?.getBoundingClientRect().top || 0;
      const menuItem = document.getElementById("menu-" + menu.id);
      // if (top < window.innerHeight / 1.65 && top >= 0) {
      if (top < 80 + this.tempTop) {
        if (this.yScrolling === false) {
          let top = menuItem?.offsetTop || 0;
          if (top >= 54) {
            top -= 54;
          }
          window.requestAnimationFrame(() => {
            let top = menuItem?.offsetTop || 0;
            if (top >= 54) {
              top -= 54;
            }
            document.getElementById("menu-list")?.scrollTo({
              left: menuItem?.offsetLeft || 0,
              top: top,
              behavior: "auto"
            });
            this.yScrolling = true;
          });
        }
        this.yScrolling = false;
        if (!this.isSearching && menu.id !== this.focusingMenu)
          this.focusingMenu = menu.id;
        return menu.id;
      } else return "";
    });
  }
  async handleScroll() {
    await new Promise(this.detectInBound);
  }

  openPromoModal(campaigns) {
    this.$ionic.modalController
      .create({
        component: PromoModal as any,
        componentProps: {
          data: {},
          propsData: {
            title: "Promotions",
            $router: this.$router,
            $t: this.$t,
            self: this,
            campaigns
          }
        }
      })
      .then((m) => m.present());
  }

  getIconTip(content) {
    return {
      content: content,
      trigger: "hover focus click"
    };
  }

  created() {
    dayjs.extend(customParseFormat);
    dayjs.extend(utc);
    dayjs.extend(isBetween);
    dayjs.extend(calendar);
  }

  mounted() {
    this.creditTopUpBanner();
  }

  creditTopUpBanner() {
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get("message");
    if (message) {
      var color: string;
      if (message.toLocaleLowerCase().includes("successfully")) {
        color = "success";
      } else if (message.toLocaleLowerCase().includes("cancelled")) {
        color = "warning";
      } else if (message.toLocaleLowerCase().includes("failed")) {
        color = "danger";
      } else {
        color = "success";
      }
      this.$ionic.toastController
        .create({
          header: message,
          position: "top",
          color: color,
          duration: 5000,
          buttons: [
            {
              icon: "close",
              role: "cancel"
            }
          ]
        })
        .then((x) => x.present());
      urlParams.delete("message");
      const newUrl = `${window.location.origin}${
        window.location.pathname
      }?${urlParams.toString()}`;
      window.history.replaceState(
        {},
        "",
        newUrl.endsWith("?") ? newUrl.slice(0, -1) : newUrl
      );
    }
  }

  async activated() {
    setTimeout(() => {
      document.addEventListener("scroll", this.handleScroll);
    }, 250);

    window.innerWidth > 1024 ? (this.tempTop = 0) : (this.tempTop = 125);
    window.onresize = () => {
      window.innerWidth > 1024 ? (this.tempTop = 0) : (this.tempTop = 125);
    };

    // this.reserveTableGuide();
    if (!(await cookies.get("hasShownTableReserveGuide"))) {
      this.reserveTableGuide();
      cookies.set("hasShownTableReserveGuide", true);
    }
  }
  deactivated() {
    {
      const bottomSheet: any = this.$refs.reserveTableBottomSheet;
      bottomSheet?.close();
      document.removeEventListener("scroll", this.handleScroll);
    }
  }

  sendWhatsAppMessage(number) {
    const waLink = `https://api.WhatsApp.com/send?phone=${number}&text=Hello !`;
    if (isNestle()) {
      rmPlugin.sendMessage({
        action: "WHATS_APP_LINK",
        message: waLink
      });
    } else {
      window.open(waLink, "_blank");
    }
  }

  contactUs(): void {
    const number =
      this.currentStore?.whatsapp?.waCountryCode &&
      this.currentStore?.whatsapp?.waPhoneNumber
        ? `${this.currentStore.whatsapp.waCountryCode}${this.currentStore.whatsapp.waPhoneNumber}`
        : "";
    const waLink = `https://api.WhatsApp.com/send?phone=${number}&text=${encodeURIComponent(
      `Hello ${this.currentStore?.name} !`
    )}`;
    window.open(waLink, "_blank");
  }

  backPressed() {
    if (window.history.length == merchantStore.initHistoryLen) {
      this.$router.replace({ name: "StoreList" });
    } else {
      this.$router.go(-1);
    }
  }
  public deobuncedHandleSearch = debounce(this.handleSearch, 250);

  handleSearch(e) {
    const value = e.target.value;
    this.query = value;
    const query = value.trim();
    if (query) {
      let options = {
        includeScore: false,
        ignoreLocation: true,
        threshold: 0.4
      };

      options["keys"] = [
        "name",
        "description",
        "menuName",
        "sides.name",
        "sides.items.name",
        "attributes.name",
        "attributes.labels.name"
      ];

      this.queryList = new Fuse(menuStore.allFoods || [], options)
        .search(query)
        .map((each) => each.item);
    } else {
      this.isSearching = false;
      this.queryList = [];
      this.menuClicked(this.focusingMenu);
    }
  }

  toggleTopUpBottomSheet() {
    const bottomSheet: any = this.$refs.topUpBottomSheet;
    const refBottomSheet: any = bottomSheet.$refs.topUpBottomSheet;
    if (!this.isTopUpSheetOpened) {
      refBottomSheet?.open();
    } else {
      refBottomSheet?.closed();
    }
  }

  handleClickLoyaltyCreditTopUp() {
    this.toggleTopUpBottomSheet();
  }

  showTopUpSheetStatus(value) {
    this.isTopUpSheetOpened = value;
  }

  handleTopUpSheetConfirmClick() {
    this.toggleTopUpBottomSheet();
    this.$ionic.modalController
      .create({
        component: MessageModal as any,
        componentProps: {
          data: {},
          propsData: {
            title: "Reload Success",
            message: "Current balance: 26 credits"
          }
        }
      })
      .then((m) => {
        m.present();
        history.pushState(null, "", window.location.href);
      });
  }
}
