<template>
  <div>
    <ion-header class="px-4 pt-2">
      <ion-toolbar class="pb-2">
        <ion-buttons slot="start">
          <ion-button @click="closeModal" class="w-8 h-8">
            <SvgIcon name="chevron-back-outline" />
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <div class="gradient"></div>
    <ion-content class="ion-padding">
      <main class="px-4 py-4 pt-0 h-full">
        <div
          class="pb-4 flex flex-col items-center justify-center h-full gap-y-6"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="#fed622"
            class="h-20 w-20"
          >
            <path
              fill-rule="evenodd"
              d="M16.403 12.652a3 3 0 0 0 0-5.304 3 3 0 0 0-3.75-3.751 3 3 0 0 0-5.305 0 3 3 0 0 0-3.751 3.75 3 3 0 0 0 0 5.305 3 3 0 0 0 3.75 3.751 3 3 0 0 0 5.305 0 3 3 0 0 0 3.751-3.75Zm-2.546-4.46a.75.75 0 0 0-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z"
              clip-rule="evenodd"
            />
          </svg>
          <div class="text-gray-700">{{ message }}</div>
        </div>
      </main>
    </ion-content>
  </div>
</template>

<script>
import { Prop, Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class MessageModal extends Vue {
  @Prop({ required: true, default: "Message" }) title;
  @Prop({ required: true, default: "" }) message;

  data() {
    return {};
  }

  // Close modal function
  closeModal() {
    this.$ionic.modalController.dismiss();
  }

  mounted() {
    // Listen for the back gesture using popstate
    window.addEventListener("popstate", this.handleBackButton);
  }

  beforeDestroy() {
    // Clean up the event listener when the modal is destroyed
    window.removeEventListener("popstate", this.handleBackButton);
  }

  // Handle the back button action
  handleBackButton(event) {
    // Check if modal is open
    if (this.$ionic.modalController.getTop()) {
      this.closeModal();
      event.preventDefault(); // Prevent default back navigation behavior
    }
  }
}
</script>

<style lang="scss" scoped>
.gradient {
  height: 20px;
  position: relative;
  width: 100%;
  background: radial-gradient(
    ellipse farthest-side at top center,
    rgba(0, 0, 0, 0.06),
    transparent
  );

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    height: 3px;
    background: linear-gradient(
      to right,
      transparent,
      rgba(0, 0, 0, 0.02),
      rgba(0, 0, 0, 0.02),
      transparent
    );
  }
}
</style>
